class TabsOfSearchTypes {
    static init() {
        $("#tab-button-parameter").click(function() {
            TabsOfSearchTypes.setActive(this);
            //hide ra, dec, radius
            $("#tab-content-location").hide();
            $("#search-conditions-section").show();
            //clear the target name or else the search button will use target name
            $(".target-name-prompt").hide();
            $("#inp-target-name").val("");
            $(".region-container").show();
            $("#regionleft").show();
            $(".catalog-prompt").show();
            $("#catalog-outer").show();
            TabsOfSearchTypes.closeAllAlerts();
        });
        $("#tab-button-coordinates").click(function() {
            TabsOfSearchTypes.setActive(this);
            //show everything
            $("#tab-content-location").show();
            $("#tab-form-coordinate").show();
            //to show, add d-flex class
            $("#tab-form-coordinate").addClass("d-flex");
            $(".coordinates-prompt").show();
            $("#tab-form-target").hide();
            $(".target-name-prompt").hide();
            $("#tab-location-coord").click();
            $("#search-conditions-section").show();
            //clear the target name or else the search button will use target name
            $("#inp-target-name").val("");
            $(".region-container").show();
            $("#regionleft").show();
            $(".catalog-prompt").show();
            $("#catalog-outer").show();
            TabsOfSearchTypes.closeAllAlerts();
        });
        $("#tab-button-object").click(function() {
            TabsOfSearchTypes.setActive(this);
            //hide ra, dec, radius, region, catalog, conditions
            $("#tab-content-location").show();
            $("#tab-form-coordinate").hide();
            //to hide, remove d-flex class
            $("#tab-form-coordinate").removeClass("d-flex");
            $("#tab-form-coordinate").hide();
            $(".coordinates-prompt").hide();
            $(".target-name-prompt").show();
            $("#tab-form-target").show();
            $("#tab-location-target").click();
            $("#regionleft").hide();
            $(".region-container").hide();
            $(".catalog-prompt").hide();
            $("#catalog-outer").hide();
            // delete search conditions
            const SearchConditions = require("./search-conditions.js");
            SearchConditions.empty();
            $("#search-conditions-section").hide();
            TabsOfSearchTypes.closeAllAlerts();
        });
    }
    static setActive(button) {
        var isDisabled = $(button).hasClass("disabled");
        if (!isDisabled) {
            $(button)
                .addClass("active")
                .siblings()
                .removeClass("active");
        }
    }
    static closeAllAlerts() {
        $("#provide-radius").hide();
        $("#alert-resolution").removeClass("show");
    }
    static getSearchType() {
        var ret = "object";
        if (
            $("#tab-button-parameter")
                .parent()
                .hasClass("active")
        ) {
            ret = "parameter";
        }
        if (
            $("#tab-button-coordinates")
                .parent()
                .hasClass("active")
        ) {
            ret = "coordinates";
        }
        if (
            $("#tab-button-object")
                .parent()
                .hasClass("active")
        ) {
            ret = "object";
        }
        return ret;
    }
}
module.exports = TabsOfSearchTypes;
