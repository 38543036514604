const ZmastLocalStorage = require("./zmast-local-storage.js");
const SedSettingsStorage = require("./sed-settings-storage");

class PlotTabs {
    static init() {
        $("#tabs").tabs();
        $("#sed-settings-tabs").tabs();
    }
    static displaySedPlot(removePrevious) {
        if (removePrevious) {
            // If previous chart is in place, remove it.
            // This occurs when user changes SED settings.
            $(".bk-root").html("<h4 class='ml-4'>Rebuilding SED plot</h4>");
        }
        const objId = ZmastLocalStorage.getObjectId();
        if (objId == undefined || objId == null) {
            console.error("objId: " + objId);
        } else {
            const survey = ZmastLocalStorage.getSurvey();
            //If local storage is empty, use default values.
            const xAxisType = SedSettingsStorage.getSedXScaling() == null ? "linear" : SedSettingsStorage.getSedXScaling();
            const yAxisType = SedSettingsStorage.getSedYScaling() == null ? "log" : SedSettingsStorage.getSedYScaling();
            const xUnits = SedSettingsStorage.getSedXUnits() == null ? "wavelength" : SedSettingsStorage.getSedXUnits();
            const yUnits = SedSettingsStorage.getSedYUnits() == null ? "nu_f_nu" : SedSettingsStorage.getSedYUnits();
            $.ajax({
                url:
                    "/api/v0.1/sed/" +
                    survey +
                    "/plot/?embed&id=" +
                    objId +
                    "&height=416" +
                    "&x_axis_type=" +
                    xAxisType +
                    "&y_axis_type=" +
                    yAxisType +
                    "&x_units=" +
                    xUnits +
                    "&y_units=" +
                    yUnits,
                crossDomain: true,
            })
                .done(function (data, status, xhr) {
                    $(".bk-root").remove();
                    $(document.head).append(data.script);
                    $("#sed-buttons").before(data.div);
                })
                .fail(function (data, status, xhr) {
                    console.error(data.statusText);
                    $("#sed-buttons").html("SED plot is not available for this object");
                });
        }
    }
}
module.exports = PlotTabs;
