const ZipReader = require("./zip-reader.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const Constants = require("./constants.js");

class StaticImages {
    static init() {
        StaticImages.imageCount = 0;
        $("#images-row").empty();
        StaticImages.getImages();
        $("#download-images").click(StaticImages.downloadImageFiles);
    }
    static show(file) {
        var id = "fits-images-" + StaticImages.imageCount++;
        // make up the html with the unique id.
        const fileProps = FilenameParser.parse(file);
        const html = sprintf(
            " <figure><figcaption class='image-caption'> <span class='value'>%s : </span><br/> <span class='value'>%s</span> </figcaption><img class='z-image' id='%s' src='%s'> </figure> ",
            fileProps.filter,
            fileProps.resolution,
            id,
            file
        );
        $(html).appendTo($("#images-row"));
    }
    static getImages() {
        const apiUrl = this.getApiUrl();
        ZipReader.getFileList(apiUrl, ZmastLocalStorage.getSurvey());
    }
    static getApiUrl() {
        const coords = { ra: ZmastLocalStorage.getRA(), dec: ZmastLocalStorage.getDec() };
        const size = { x: 5, y: 5 };
        const apiUrl = sprintf("/zcut/api/v0.1/astrocut?ra=%f&dec=%f&x=%s&y=%s&units=s&format=jpg", coords.ra, coords.dec, size.x, size.y);
        return apiUrl;
    }
    static getSurveyList(coords) {
        var filter60mas = "";
        // users don't specify region for object search, so we have to do a survey query
        const apiUrl = sprintf("/zcut/api/v0.1/survey?ra=%f&dec=%f&radius=5s", coords.ra, coords.dec);
        $.ajaxSetup({
            async: false,
        });
        $.getJSON(apiUrl, function (result) {
            $.each(result.surveys, function (i, field) {
                if (field.endsWith("60mas")) {
                    filter60mas = "&survey=" + field;
                }
            });
        });
        $.ajaxSetup({
            async: true,
        });
        return filter60mas;
    }
    static getExportFileType() {
        return $("#select-image-file-type  option:selected").val();
    }
    static downloadImageFiles() {
        const fileType = StaticImages.getExportFileType();
        var apiUrl = StaticImages.getApiUrl();
        apiUrl = apiUrl.replace("format=jpg", "format=" + fileType);
        console.log(apiUrl);
        window.open(apiUrl);
    }
}

module.exports = StaticImages;
