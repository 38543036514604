class Spectra {
    //todo: consider calling from plot-tabs.js
    static init() {
        //hide spectra tab until it is fully operational
        if (Spectra.hasParam("spectra")) {
            //$("main.v-content").css({ "margin-top": "-48px" });
        } else {
            $("#spectra-tab-header").hide();
        }
    }
    static hasParam(param) {
        const url = new URL(document.location);
        const value = url.searchParams.has(param);
        return value;
    }
}
module.exports = Spectra;
