const ObjectInfo = require("./object-info.js");
const toFixed = require("./number-formatter.js");

class ObjectInfo3Dhst extends ObjectInfo {
    getObjectInfo(json) {
        var objectInfoData;
        if (json.data[0]) {
            const record = json.data[0];
            objectInfoData = {
                id: record.ID,
                ra: record.RA,
                dec: record.DEC,
                z_spec: record.zspec,
                z_peak: record.zbest,
                lmass: record.lmass,
            };
        } else {
            objectInfoData = {
                id: "",
                ra: "",
                dec: "",
                z_spec: "",
                z_peak: "",
                lmass: "",
            };
        }
        return objectInfoData;
    }
    getPhotometry(json) {
        var photometryData;
        if (json.data[0]) {
            const record = json.data[0];
            photometryData = {
                ACS_F435W: record.ACS_F435W_MAG,
                ACS_F606W: record.ACS_F606W_MAG,
                ACS_F775W: record.ACS_F775W_MAG,
                ACS_F814W: record.ACS_F814W_MAG,
                ACS_F850LP: record.ACS_F850LP_MAG,
                WFC3_F098M: record.WFC3_F098M_MAG,
                WFC3_F105W: record.WFC3_F105W_MAG,
                WFC3_F125W: record.WFC3_F125W_MAG,
                WFC3_F140W: record.WFC3_F140W_MAG,
                WFC3_F160W: record.WFC3_F160W_MAG,
                IRAC_CH1: record.IRAC_CH1_MAG,
                IRAC_CH2: record.IRAC_CH2_MAG,
                IRAC_CH3: record.IRAC_CH3_MAG,
                IRAC_CH4: record.IRAC_CH4_MAG,
            };
        } else {
            photometryData = {};
        }
        return photometryData;
    }
    getMorphology(json) {
        var morphologyData;
        if (json.data[0]) {
            const record = json.data[0];
            morphologyData = {
                kron_radius: record.KRON_RADIUS,
                a_image: record.A_IMAGE,
                b_image: record.B_IMAGE,
                fwhm_image: record.FWHM_IMAGE,
                flux_radius: record.FLUX_RADIUS,
            };
        } else {
            morphologyData = {};
        }
        return morphologyData;
    }
    display(json) {
        $("#object-info-candels").hide();
        $("#object-info-goods").hide();
        $("#object-info-deepspace").hide();
        $("#object-info-3dhst").show();
        super.display(json);
    }

    displayObjectInfo(objectInfoData) {
        $("#id-3dhst strong").text(objectInfoData.id);
        $("#ra-3dhst strong").text(objectInfoData.ra);
        $("#dec-3dhst strong").text(objectInfoData.dec);
        $("#z_spec_3dhst strong").text(toFixed(objectInfoData.z_spec, 4));
        $("#z_peak_3dhst strong").text(toFixed(objectInfoData.z_peak, 4));
        $("#lmass_3dhst strong").text(toFixed(objectInfoData.lmass, 4));
    }
    displayPhotometry(photometryData) {
        $("#ACS_F435W_3dhst .value").text(toFixed(photometryData.ACS_F435W, 4));
        $("#ACS_F606W_3dhst .value").text(toFixed(photometryData.ACS_F606W, 4));
        $("#ACS_F775W_3dhst .value").text(toFixed(photometryData.ACS_F775W, 4));
        $("#ACS_F814W_3dhst .value").text(toFixed(photometryData.ACS_F814W, 4));
        $("#ACS_F850LP_3dhst .value").text(toFixed(photometryData.ACS_F850LP, 4));
        $("#WFC3_F098M_3dhst .value").text(toFixed(photometryData.WFC3_F098M, 4));
        $("#WFC3_F105W_3dhst .value").text(toFixed(photometryData.WFC3_F105W, 4));
        $("#WFC3_F125W_3dhst .value").text(toFixed(photometryData.WFC3_F125W, 4));
        $("#WFC3_F140W_3dhst .value").text(toFixed(photometryData.WFC3_F140W, 4));
        $("#WFC3_F160W_3dhst .value").text(toFixed(photometryData.WFC3_F160W, 4));
        $("#IRAC_CH1_3dhst .value").text(toFixed(photometryData.IRAC_CH1, 4));
        $("#IRAC_CH2_3dhst .value").text(toFixed(photometryData.IRAC_CH2, 4));
        $("#IRAC_CH3_3dhst .value").text(toFixed(photometryData.IRAC_CH3, 4));
        $("#IRAC_CH4_3dhst .value").text(toFixed(photometryData.IRAC_CH4, 4));
    }
    displayMorphology(morphologyData) {
        $("#kron_radius_3dhst .value").text(toFixed(morphologyData.kron_radius, 4));
        $("#a_image_3dhst .value").text(toFixed(morphologyData.a_image, 4));
        $("#b_image_3dhst .value").text(toFixed(morphologyData.b_image, 4));
        $("#fwhm_image_3dhst .value").text(toFixed(morphologyData.fwhm_image, 4));
        $("#flux_radius_3dhst .value").text(toFixed(morphologyData.flux_radius, 4));
    }
}
module.exports = ObjectInfo3Dhst;
