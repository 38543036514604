const DEFAULT_RADIUS_DEGREES = 0.02;
const DEFAULT_RADIUS_ARC_SECS = 1.2;

class RadiusConverter {
    static getDegrees(radius, radiusUnits) {
        if (radius < 0) {
            radius = 0;
        }
        var value = 0;
        switch (radiusUnits.toLowerCase()) {
            case "degrees":
                value = radius;
                break;
            case "arc minutes":
                value = radius / 60;
                break;
            case "arc seconds":
                value = radius / 3600;
                break;
            default:
                value = DEFAULT_RADIUS_DEGREES;
        }
        return value;
    }
    static getArcSeconds(radius, radiusUnits) {
        if (radius < 0) {
            radius = 0;
        }
        var value = 0;
        switch (radiusUnits.toLowerCase()) {
            case "degrees":
                value = radius * 3600;
                break;
            case "arc minutes":
                value = radius * 60;
                break;
            case "arc seconds":
                value = radius;
                break;
            default:
                value = DEFAULT_RADIUS_ARC_SECS;
        }
        return value;
    }
}
module.exports = RadiusConverter;
