class SearchResultsWaitMessage {
    static show(text) {
        $("#results").css("height", "200px");
        $("#results").loadingModal({
            text: text,
            color: "#00617E",
            backgroundColor: "lightGray",
            animation: "wave"
        });
    }
    static destroy() {
        $("#results").loadingModal("destroy");
        $("#results").css("height", "20px");
    }
}

module.exports = SearchResultsWaitMessage;
