class ImageTools {
    static initExpander() {
        $(".image-expander-small").click(function (event) {
            const target = $(event.target);
            if (target.hasClass("image-expander-small")) {
                target.removeClass("image-expander-small");
                target.addClass("image-expander-large");
            } else {
                target.removeClass("image-expander-large");
                target.addClass("image-expander-small");
            }
        });
    }
}
module.exports = ImageTools;
