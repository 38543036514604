const ZmastLocalStorage = require("./zmast-local-storage.js");

class ObjectName {
    static display() {
        const name = ZmastLocalStorage.getTargetName();
        $("#object-name").text(name);
    }
}

module.exports = ObjectName;
