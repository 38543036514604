const Region = require("./region.js");
const Catalog = require("./catalog.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");

class Survey {
    static init() {
        //these fields are shown for CANDELS and hidden for GOODS
        const SHOW_REGIONS_LIST = {
            CANDELS: ["#egs", "#cosmos", "#goods-north", "#goods-south", "#uds"],
            GOODS: ["#goods-north", "#goods-south"],
            DEEPSPACE: [],
            "3DHST": [],
        };
        $("#survey #candels").click(function () {
            //nothing to hide
            Survey.showRegions(SHOW_REGIONS_LIST.CANDELS);
            $("#alert-resolution").removeClass("show");
        });
        $("#survey #goods").click(function () {
            Survey.hideRegions(SHOW_REGIONS_LIST.CANDELS);
            Survey.showRegions(SHOW_REGIONS_LIST.GOODS);
            $("#alert-resolution").removeClass("show");
        });
        $("#survey #deepspace").click(function () {
            Survey.hideRegions(SHOW_REGIONS_LIST.CANDELS);
            Survey.showRegions(SHOW_REGIONS_LIST.DEEPSPACE);
            $("#alert-resolution").removeClass("show");
        });
        $("#survey #3dhst").click(function () {
            Survey.hideRegions(SHOW_REGIONS_LIST.CANDELS);
            Survey.showRegions(SHOW_REGIONS_LIST["3DHST"]);
            $("#alert-resolution").removeClass("show");
        });
        $("#survey").click(function () {
            $("#provide-radius").hide();
            Region.init();
            Catalog.display();
        });
        const survey = ZmastLocalStorage.getSurvey();
        $("input[name=survey][value=" + survey + "]").click();
    }
    static showRegions(list) {
        for (var region of list) {
            $(region).parent().show();
            //add dividers
            $(region).parent().prev().show();
        }
    }
    static hideRegions(list) {
        for (var region of list) {
            $(region).parent().hide();
            //Remove dividers
            $(region).parent().prev().hide();
        }
    }
    static disableButtons() {
        $("#survey .disabled").click(function (event) {
            $("#survey .disabled").removeClass("active");
            return false;
        });
    }
}
module.exports = Survey;
