var FitsImages = require("./fits-images.js");

class ImageKeyHandler {
    static init() {
        var prev = 0;
        const ctrl = 17;
        $("#tabs").keydown(function(event) {
            if (prev === ctrl && event.which === 71) {
                FitsImages.init();
            }
            if (prev === ctrl && event.which === 72) {
                StaticImages.init();
            }
            prev = event.which;
        });
    }
}
module.exports = ImageKeyHandler;
