const UserInterfaceCustomizations = require("./user-interface-customizations.js");
const TabsOfSearchTypes = require("./tabs-of-search-types.js");
const QueryBuilder = require("./query-builder.js");
const CoordinatesModule = require("./coordinates-module.js");

class SearchButton {
    static init() {
        $("#search-results-button").click(function() {
            UserInterfaceCustomizations.saveFormValues();
            let queryBuilder = new QueryBuilder();
            if ("parameter" === TabsOfSearchTypes.getSearchType()) {
                queryBuilder.gotoResults();
            } else {
                SearchButton.resolveAndGotoResults();
            }
        });
    }
    static resolveAndGotoResults() {
        //TODO: if isResolved, don't resolve again
        CoordinatesModule.GOTO_RESULTS = true;
        CoordinatesModule.resolve();
    }
    static enable() {
        $("#search-results-button").removeClass("disabled");
        $("#search-results-button").attr("data-original-title", "Search Catalog");
    }
    static disable() {
        $("#search-results-button").addClass("disabled");
        $("#search-results-button").attr("data-original-title", "Enter a target to enable this button");
    }
}
module.exports = SearchButton;
