const Downloader = require("./downloader.js");
const Constants = require("./constants.js");

var bodyModalLoaded;

class CaomDownloader extends Downloader {
    constructor() {
        super();
    }
    download() {
        // create a blob of data based on the output dropdown and attach it to the document
        // options - an object containing perhaps a success/error callback
        const options = {};
        const self = this;
        const type = $("#sel-filetype").val();
        const api = this.searchResults.getApi();
        const postData = this.searchResults.getPostData();
        if ("download" in HTMLAnchorElement.prototype) {
            const filetype = type == "votable" ? "xml" : type;
            const request = new XMLHttpRequest();
            request.addEventListener("readystatechange", function (e) {
                if (request.readyState === Constants.REQUEST_COMPLETE) {
                    const contentType = request.getResponseHeader("Content-Type");
                    const data = request.response;
                    $("body").loadingModal("hide");
                    bodyModalLoaded = true;
                    if (request.status == 200) {
                        self.extractData(data, filetype, contentType, options);
                    } else {
                        if (options.error) {
                            options.error.call(self, request, request.status, request.statusText);
                            GaWrapper.writeGaEvent("Z MAST", "Download CAOM data", "Failure");
                        }
                    }
                }
            });
            if (!bodyModalLoaded) {
                // create new fullscreen modal if first time downloading
                $("body").loadingModal({
                    text: Constants.UI_MESSAGES_CAOM.DOWNLOADING,
                    color: "#00617E",
                    backgroundColor: "#FFF",
                    animation: "wave",
                    opacity: 1,
                });
            } else {
                // reuse if this modal was simply hidden, ostensibly rare that anyone would do this
                $("body").loadingModal("show");
            }
            request.responseType = "blob";
            request.open(this.getHttpMethod(), api);
            request.setRequestHeader("Content-type", "application/x-www-form-urlencoded; charset=UTF-8");
            request.setRequestHeader("Accept", this.getAcceptHeader(type));
            request.send($.param(postData));
        } else {
            // Download is not available in certain browsers such as Safari
            // Instead, open in new window
            window.open(api);
            if (options.success) {
                options.success.call(self, self.messages.cutout_download_new_window);
                GaWrapper.writeGaEvent("Z MAST", "Download data", "Open in Window");
            }
        }
    }
    getHttpMethod() {
        return "GET";
    }
}

module.exports = CaomDownloader;
