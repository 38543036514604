const SearchResults = require("./search-results.js");
const SearchParamsMessage = require("./search-params-message.js");
const SearchResultsWaitMessage = require("./search-results-wait-message.js");
const ErrorMessage = require("./error-message.js");
const Constants = require("./constants.js");
const ObjectIdLookup = require("./object-id-lookup.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const DownloadButtons = require("./download-buttons.js");
const TableFilters = require("./table-filters.js");
const ZmastParameterManager = require("./zmast-parameter-manager.js");

const DIGITS_AFTER_DECIMAL = 3;
const EXCLUDE_FROM_NUMBER_FORMATTING = ["RA", "DEC", "ID", "OBJID"];
const DEFAULT_PARAMETERS_OBJ = {
    flatten_response: false,
    raw: false,
    sort_by: ["distance"],
};
const LARGE_CATALOGS = ["catalogrecord", "hlsp_3dhst_catalogrecord"];

class ZmastSearchResults extends SearchResults {
    getTarget() {
        const self = this;
        const survey = ZmastLocalStorage.getSurvey();
        const region = ZmastLocalStorage.getRegion();
        const api = this.getApi(survey);
        const postData = this.getPostData();
        self.setTargetMessage();
        if (!this.isSupported(survey, region)) {
            ErrorMessage.setMessage("Region " + region.toUpperCase() + " not yet supported");
        } else {
            SearchResultsWaitMessage.show(Constants.UI_MESSAGES_ZMAST.LOADING);
            //TODO: success and error are deprecated, use done and fail
            $.ajax({
                url: api,
                method: this.getHttpMethod(),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(postData),
                success: function () {
                    SearchResultsWaitMessage.destroy();
                },
                error: function (data, textStatus, errorThrown) {
                    console.error(textStatus + ": " + errorThrown);
                    ErrorMessage.setMessage(textStatus + ": " + errorThrown);
                    SearchResultsWaitMessage.destroy();
                },
            }).done(function (jsonString) {
                if (jsonString.data.length === 0) {
                    ErrorMessage.setNoRecordsMessage("No records found");
                    DownloadButtons.disable();
                } else {
                    self.displayDataTable(jsonString);
                }
            });
        }
    }
    //API has metadata/info, so we can get column names from the metadata/info
    getColumnsFromResponse(info) {
        const columns = [];
        var name = "Action";
        columns.push({ data: name, title: name });
        for (var index = 0; index < info.length; index++) {
            name = info[index].name.replace(".", "\\.");
            columns.push({ data: name, title: name, name: name, className: "col_" + name });
        }
        return columns;
    }
    setTargetMessage() {
        var conditions;
        var target;
        if (ZmastLocalStorage.getSearchType() === "parameter") {
            if (ZmastLocalStorage.getSearchConditionsForApi()) {
                conditions = " conditions: " + JSON.stringify(ZmastLocalStorage.getSearchConditionsForApi());
            }
        } else {
            var ra = Number(ZmastLocalStorage.getRA());
            var dec = Number(ZmastLocalStorage.getDec());
            var radius = Number(ZmastLocalStorage.getRadiusForApi());
            var searchString = ZmastLocalStorage.getSearchString();
            var canonicalName = ZmastLocalStorage.getTargetName();
            if (searchString === null || searchString === "" || canonicalName.indexOf("Equatorial Coord") !== -1) {
                target = "";
            } else {
                target = "Target: <strong>" + searchString + "/" + canonicalName + "</strong> ";
            }
        }

        var msg = "<strong> " + ZmastLocalStorage.getCatalog() + "</strong> ";
        if (target) msg += target;
        if (conditions) msg += conditions;
        if (ra && dec && radius) {
            msg +=
                "RA= <strong> " +
                ra.toFixed(5) +
                "</strong>  dec= <strong> " +
                dec.toFixed(5) +
                "</strong>  Radius: <strong> " +
                radius.toFixed(5) +
                "</strong>\xB0";
        }
        SearchParamsMessage.setMessage(msg);
    }
    getApi(survey) {
        return Constants.DATA_URL + survey;
    }
    getPostData() {
        var postData = {};
        const catalog = ZmastLocalStorage.getCatalog();
        const searchConditions = ZmastLocalStorage.getSearchConditionsForApi();
        if (ZmastLocalStorage.getSearchType() === "parameter") {
            // Large queries (queries with no params) on large catalogs often have timeout errors, so we will use a smaller pagesize.
            postData = {
                catalog: catalog,
                pagesize: LARGE_CATALOGS.includes(catalog) ? 5000 : 10000,
            };
        } else {
            const ra = ZmastLocalStorage.getRA();
            const dec = ZmastLocalStorage.getDec();
            const radius = ZmastLocalStorage.getRadiusForApi();
            postData = {
                ra: ra,
                dec: dec,
                radius: radius,
                catalog: catalog,
            };
        }
        $.extend(postData, searchConditions, DEFAULT_PARAMETERS_OBJ);
        return postData;
    }
    static getObjectId(row, catalog) {
        //console.log(row["goods_master_view.objid"]);
        //console.log(row);
        const propName = "objID";
        var objid;
        if (row.hasOwnProperty(propName)) {
            objid = row[propName];
        }

        return objid;
    }
    getHttpMethod() {
        return "POST";
    }
    static getColumnIndexForObjId() {
        var objidIndex = $('th:contains("objID")').index();
        if (objidIndex === -1) {
            objidIndex = $('th:contains("objid")').index();
        }
        return objidIndex;
    }
    //when someone clicks on a row, go to the object page for the specified object
    static setRowClickHandler() {
        $(".goto-object").off("click.zmast");
        $(".goto-object").on("click.zmast", function () {
            const objidIndex = ZmastSearchResults.getColumnIndexForObjId();
            const selector = sprintf("td:eq(%d)", objidIndex);
            const objid = $(this).closest("tr").find(selector).text();
            ZmastSearchResults.goToObjectPage(objid);
        });
    }
    static goToObjectPage(objid) {
        const record = ObjectIdLookup.getFirstObject(objid);
        if (record !== null) {
            ZmastLocalStorage.setSearchType("object");
            ZmastLocalStorage.setObjectId(objid);
            ZmastLocalStorage.setRA(record.ra);
            ZmastLocalStorage.setDec(record.dec);
            ZmastLocalStorage.setTargetName(record.IAU_NAME);
            ZmastParameterManager.goToObjectPage(ZmastLocalStorage.getSurvey(), objid, true);
        }
    }
    setToolTips() {
        const region = ZmastLocalStorage.getRegion();
        if (region === "cosmos") {
            const api = this.getMetadataApi();
            $.getJSON(api, function (result) {
                $("thead th").each(function () {
                    const currentTh = $(this);
                    const column = currentTh.text().trim();
                    $.each(result, function (i, field) {
                        if (field.column_name.toUpperCase() === column.toUpperCase()) {
                            //console.log("match " + field.column_name + " : " + column + " : " + field.description);
                            currentTh.attr("title", field.description);
                            //currentTh.attr("data-placement", "top");
                        }
                    });
                    /* Apply the tooltips */
                    $("thead th[title]").tooltip({
                        container: "body",
                    });
                });
            });
        }
        return null;
    }
    getMetadataApi() {
        return "/api/v0.1/" + ZmastLocalStorage.getSurvey() + "/metadata/" + ZmastLocalStorage.getCatalog() + ".json";
    }
    getCustomColumnDefinitions(formatFields) {
        return [
            {
                targets: 0,
                data: null,
                defaultContent: "<button class='btn btn-primary goto-object'>See Object</button>",
            },
            {
                targets: formatFields,
                render: function (data, type, row) {
                    if (data == null) {
                        return "";
                    } else {
                        try {
                            return data.toFixed(DIGITS_AFTER_DECIMAL);
                        } catch (err) {
                            console.error("Cannot format " + data + " as number. " + err.message);
                            return data;
                        }
                    }
                },
            },
        ];
    }
    getDatatypeDoubleFields(info) {
        //format all numeric fields except those fields in EXCLUDE_FROM_NUMBER_FORMATTING list
        const formatFields = [];
        var field;
        for (var index = 0; index < info.length; index++) {
            field = info[index];
            if (field.type === "NUMBER" && !EXCLUDE_FROM_NUMBER_FORMATTING.includes(field.name.toUpperCase())) {
                formatFields.push(index + 1);
                //add one because the first column is always a "See object" button
            }
        }
        return formatFields;
    }
    displayDataTable(jsonString) {
        var self = this;
        const columns = self.getColumnsFromResponse(jsonString.info);
        const formatFields = self.getDatatypeDoubleFields(jsonString.info);
        //console.log(formatFields);
        const table = $("#results").DataTable({
            data: jsonString.data,
            searching: true,
            processing: true,
            pageLength: 25,
            deferRender: true,
            scrollX: true,
            dom: 't<"bottom"lip>',
            language: {
                loadingRecords: "Loading...",
                processing: "Processing...",
                emptyTable: "No records found",
                infoEmpty: "No records found",
            },
            columns: columns,
            columnDefs: self.getCustomColumnDefinitions(formatFields),
            initComplete: function () {
                self.setToolTips();
            },
        });
        TableFilters.init(table, ["Action"]);
        //set up the row click handler for the first page and set it up again anytime a page/draw event occurs
        ZmastSearchResults.setRowClickHandler();
        table.on("draw.dt", function () {
            ZmastSearchResults.setRowClickHandler();
        });
    }
}

module.exports = ZmastSearchResults;
