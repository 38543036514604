const SearchConditions = require("./search-conditions.js");
const TabsOfSearchTypes = require("./tabs-of-search-types.js");
const Catalog = require("./catalog.js");
const CoordinatesModule = require("./coordinates-module.js");
const RadiusConverter = require("./radius-converter.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const GaWrapper = require("./ga-wrapper.js");
const ObjectNameLookup = require("./object-name-lookup.js");
const ZmastParameterManager = require("./zmast-parameter-manager.js");

const MAX_RADIUS_ARC_SECS = 1800;

class QueryBuilder {
    getTarget() {
        var ra = null;
        var dec = null;
        if (CoordinatesModule.submitParams.ra === null) {
            ra = CoordinatesModule.submitParams.target.ra;
        } else {
            ra = CoordinatesModule.submitParams.ra;
        }
        if (CoordinatesModule.submitParams.dec === null) {
            dec = CoordinatesModule.submitParams.target.dec;
        } else {
            dec = CoordinatesModule.submitParams.dec;
        }
        if (ra === null || dec === null) {
            return null;
        }
        return "ra=" + ra + "&dec=" + dec;
    }
    isRadiusValid() {
        const radius = $("#search-radius").val();
        if (radius === "") {
            return false;
        }
        return !isNaN(radius);
    }
    getRadiusForUi() {
        return $("#search-radius").val();
    }
    getRadiusForApi() {
        const radius = $("#search-radius").val();
        const radiusUnits = $("#search-radius-units").val();
        //console.log(radius + " " + radiusUnits);
        // API expects arc seconds
        const arcSeconds = RadiusConverter.getDegrees(radius, radiusUnits);
        return Math.min(arcSeconds, MAX_RADIUS_ARC_SECS);
    }
    getRa() {
        var ra = null;
        if (CoordinatesModule.submitParams.ra === null) {
            ra = CoordinatesModule.submitParams.target.ra;
        } else {
            ra = CoordinatesModule.submitParams.ra;
        }
        if (ra === null) {
            return null;
        }
        return ra;
    }
    getDec() {
        var dec = null;
        if (CoordinatesModule.submitParams.dec === null) {
            dec = CoordinatesModule.submitParams.target.dec;
        } else {
            dec = CoordinatesModule.submitParams.dec;
        }
        if (dec === null) {
            return null;
        }
        return dec;
    }
    getSelectedColumns() {
        var listOfColumns = [];
        var selectedCheckboxes = $("#grid-of-checkboxes input:checked");
        selectedCheckboxes.each(function () {
            listOfColumns.push($(this).attr("id"));
        });
        const catalog = Catalog.getCatalog();
        if (catalog === "summary") {
            listOfColumns = listOfColumns.concat(SUMMARY_FILTERS);
        }
        return JSON.stringify(listOfColumns);
    }

    //go to results page for a target
    gotoResults() {
        if (SearchConditions.isValid()) {
            const queryBuilder = new QueryBuilder();
            var searchType = TabsOfSearchTypes.getSearchType();
            var isMultiObject = false;
            var target;
            if (CoordinatesModule.resolution) {
                target = {
                    ra: CoordinatesModule.resolution.ra,
                    dec: CoordinatesModule.resolution.decl,
                    searchString: CoordinatesModule.resolution.searchString,
                    canonicalName: CoordinatesModule.resolution.canonicalName,
                };
                isMultiObject = ObjectNameLookup.getCount(target.canonicalName) > 1;
            }
            if (isMultiObject) {
                searchType = "parameter";
                var searchConditions;
                if (Catalog.getSurvey() === "deepspace") {
                    searchConditions = { Name: target.canonicalName };
                } else {
                    searchConditions = { IAU_Name: target.canonicalName };
                }
                ZmastLocalStorage.setSearchConditionsForApi(searchConditions);
                ZmastLocalStorage.setSearchType(searchType);
                ZmastLocalStorage.setSurvey(Catalog.getSurvey());
                ZmastLocalStorage.setRegion("all");
                const catalog = Catalog.getSurvey() === "deepspace" ? "deepspace_summary" : "candels_master_view";
                ZmastLocalStorage.setCatalog(catalog);

                ZmastParameterManager.goToCoordsPage({
                    survey: Catalog.getSurvey(),
                    region: Catalog.getRegion(),
                    catalog: Catalog.getCatalog(),
                    searchConditionsApi: JSON.stringify(searchConditions),
                });
                return;
            } else {
                ZmastLocalStorage.setSearchType(searchType);
                ZmastLocalStorage.setSurvey(Catalog.getSurvey());
                ZmastLocalStorage.setRegion(Catalog.getRegion());
                ZmastLocalStorage.setCatalog(Catalog.getCatalog());
                ZmastLocalStorage.setRadiusForApi(queryBuilder.getRadiusForApi());
                ZmastLocalStorage.setRadiusForUi(queryBuilder.getRadiusForUi());
                SearchConditions.save();
                ZmastLocalStorage.setSearchConditionsForApi(SearchConditions.getSearchConditions());
                if (target) {
                    ZmastLocalStorage.setRA(target.ra);
                    ZmastLocalStorage.setDec(target.dec);
                    ZmastLocalStorage.setTargetName(target.canonicalName);
                }
            }
            if ("parameter" === searchType) {
                GaWrapper.writeGaEvent(
                    "ZMAST",
                    "Search by parameter",
                    JSON.stringify({
                        survey: Catalog.getSurvey(),
                        region: Catalog.getRegion(),
                        catalog: Catalog.getCatalog(),
                        searchConditions: SearchConditions.getSearchConditions(),
                    })
                );
                ZmastParameterManager.goToCoordsPage({
                    survey: Catalog.getSurvey(),
                    region: Catalog.getRegion(),
                    catalog: Catalog.getCatalog(),
                    searchConditionsApi: JSON.stringify(SearchConditions.getSearchConditions()),
                });
            } else {
                if (this.isConeSearch(target)) {
                    GaWrapper.writeGaEvent(
                        "ZMAST",
                        "Search by coordinates",
                        JSON.stringify({
                            survey: Catalog.getSurvey(),
                            region: Catalog.getRegion(),
                            catalog: Catalog.getCatalog(),
                            ra: target.ra,
                            dec: target.dec,
                            radius: queryBuilder.getRadiusForApi(),
                            searchConditions: SearchConditions.getSearchConditions(),
                        })
                    );
                    ZmastParameterManager.goToCoordsPage({
                        survey: Catalog.getSurvey(),
                        region: Catalog.getRegion(),
                        catalog: Catalog.getCatalog(),
                        ra: target.ra,
                        dec: target.dec,
                        radius: queryBuilder.getRadiusForApi(),
                        searchConditionsApi: JSON.stringify(SearchConditions.getSearchConditions()),
                    });
                } else {
                    if (this.isTargetSearch(target)) {
                        GaWrapper.writeGaEvent(
                            "ZMAST",
                            "Search by target name",
                            JSON.stringify({
                                survey: Catalog.getSurvey(),
                                region: Catalog.getRegion(),
                                catalog: Catalog.getCatalog(),
                                target: target.canonicalName,
                            })
                        );
                        //user entered target name
                        ZmastParameterManager.goToObjectPage(Catalog.getSurvey(), ZmastLocalStorage.getObjectId());
                    } else {
                        console.error("No target provided");
                    }
                }
            }
        }
    }
    isConeSearch(target) {
        //TODO: if search returns only a single target, return false here so that user gets target search results
        return target.canonicalName.startsWith("Equatorial Coord") && $.isNumeric(target.ra) && $.isNumeric(target.dec);
    }
    isTargetSearch(target) {
        return target.searchString.length > 0;
    }
}

module.exports = QueryBuilder;
