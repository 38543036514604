class GaWrapper {
    static writeGaEvent(component, element, action) {
        if ("ga" in window && typeof ga !== "undefined" && ga !== null) {
            ga("send", {
                hitType: "event",
                eventCategory: component,
                eventAction: action,
                eventLabel: element + ": " + action
            });
        } else {
            return;
        }
    }
}
module.exports = GaWrapper;
