const TableFilters = require("./table-filters.js");
const SearchResults = require("./search-results.js");
const SearchResultsWaitMessage = require("./search-results-wait-message.js");
const ErrorMessage = require("./error-message.js");
const DownloadButtons = require("./download-buttons.js");
const Constants = require("./constants.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");

const CAOM_REQUEST_TEMPLATE = {
    service: "Mast.Caom.Filtered.Position",
    format: "json",
    pagesize: 5000,
    timeout: 60,
    removenullcolumns: false,
    data: null,
    params: {
        columns: "*",
        filters: [],
        //filters: [{ paramName: "provenance_name", values: ["hlsp_candels", "hlsp_goods", "hlsp_frontier"] }],
        position: "34.22370, -5.27800, 0.0",
        timestamp: "2019-10-04T13:26:20",
        ajaxParams: { method: "POST", url: "../../Mashup.asmx/invoke" },
    },
    clearcache: false,
    columnsconfigid: "Mast.Caom.Cone",
    page: 1,
};

class CaomSearchResults extends SearchResults {
    getTarget() {
        const self = this;
        const api = this.getApi();
        //const starttime = new Date();
        const survey = ZmastLocalStorage.getSurvey();
        const region = ZmastLocalStorage.getRegion();
        self.setTargetMessage();
        if (!this.isSupported(survey, region)) {
            ErrorMessage.setMessage("Region " + region.toUpperCase() + " not yet supported");
        } else {
            SearchResultsWaitMessage.show(Constants.UI_MESSAGES_CAOM.LOADING);
            //TODO: success and error are deprecated, use done and fail
            $.ajax({
                url: api,
                method: this.getHttpMethod(),
                dataSrc: "data",
                dataType: "json",
                contentType: "application/x-www-form-urlencoded; charset=utf-8",

                success: function () {
                    SearchResultsWaitMessage.destroy();
                    //console.log("CAOM Elapsed time (milliseconds): ", new Date() - starttime);
                },
                error: function (data, textStatus, errorThrown) {
                    console.error(textStatus + ": " + errorThrown);
                    ErrorMessage.setMessage(textStatus + ": " + errorThrown);
                    SearchResultsWaitMessage.destroy();
                },
            }).done(function (jsonString) {
                if (jsonString.data == undefined) {
                    DownloadButtons.disable();
                    console.log("No data found");
                } else {
                    const table = $("#results").DataTable({
                        data: jsonString.data,
                        dom: 't<"bottom"lip>',
                        searching: true,
                        processing: true,
                        pageLength: 25,
                        deferRender: true,
                        language: {
                            loadingRecords: "Loading...",
                            processing: "Processing...",
                            emptyTable: "No records found",
                            infoEmpty: "No records found",
                        },
                        columnDefs: self.getCustomColumnDefinitions(),
                        columns: self.getColumnNames(),
                        initComplete: function () {
                            self.setToolTips();
                        },
                    });
                    TableFilters.init(table);
                }
            });
        }
    }
    getColumns() {
        //no longer used
        const columns = [
            { data: "dataproduct_type" },
            { data: "calib_level" },
            { data: "obs_collection" },
            { data: "obs_id" },
            { data: "s_ra" },
            { data: "s_dec" },
            { data: "t_min" },
            { data: "t_max" },
            { data: "t_exptime" },
            { data: "wavelength_region" },
            { data: "filters" },
            { data: "em_min" },
            { data: "em_max" },
            { data: "target_classification" },
            { data: "obs_title" },
            { data: "t_obs_release" },
            { data: "proposal_pi" },
            { data: "proposal_id" },
            { data: "proposal_type" },
            { data: "dataRights" },
            { data: "dataURL" },
            { data: "distance" },
            { data: "instrument_name" },
            { data: "intentType" },
            { data: "jpegURL" },
            { data: "mtFlag" },
            { data: "objID" },
            { data: "obs_collection" },
            { data: "obsid" },
            { data: "project" },
            { data: "provenance_name" },
            { data: "s_region" },
            { data: "sequence_number" },
            { data: "srcDen" },
            { data: "target_name" },
        ];
        return columns;
    }
    getColumnIncludes() {
        const include = [
            "obs_collection",
            "instrument_name",
            "wavelength_region",
            "dataproduct_type",
            "filters",
            "t_exptime",
            "target_name",
            "s_ra",
            "s_dec",
            "t_min",
            "project",
            "obs_id",
        ];
        return include;
    }
    getColumnNames() {
        //TODO: unify with column includes
        const columnMap = {
            obs_collection: "Mission - Instrument",
            instrument_name: "Instrument",
            wavelength_region: "Waveband",
            dataproduct_type: "Product",
            filters: "Filters",
            t_exptime: "Exposure (sec)",
            target_name: "Target",
            s_ra: "RA",
            s_dec: "Dec",
            t_min: "Date of Observation",
            t_max: "End Time",
            project: "Project",
            obs_id: "Observation ID",
        };
        const include = this.getColumnIncludes();
        const colNames = [];
        for (var i in include) {
            colNames.push({ title: columnMap[include[i]], name: include[i], className: "col_" + include[i], data: include[i] });
        }
        return colNames;
    }

    getCustomColumnDefinitions() {
        const include = this.getColumnIncludes();
        return [
            {
                render: function (data, type, row) {
                    return convertDegreesToSexagesimal(data, 15);
                },
                targets: include.indexOf("s_ra"),
            },
            {
                render: function (data, type, row) {
                    return convertDegreesToSexagesimal(data);
                },
                targets: include.indexOf("s_dec"),
            },
            {
                render: function (data, type, row) {
                    // trim long observation IDs from HLA, Kepler and PanSTARRS
                    const obs_id = data;
                    const url = baseHost + "/api/v0.1/Download/bundle.zip?previews=false&uri=products:caom/observation/" + row.obsid;
                    const html = '<a target="iframeTarget" title="' + data + '" href="' + url + '">' + obs_id + "</a>";
                    return html;
                },
                targets: include.indexOf("obs_id"),
            },
            {
                render: function (data, type, row) {
                    return convertMjdToDate(data);
                },
                targets: include.indexOf("t_min"),
            },
            {
                render: function (data, type, row) {
                    return Math.round(Number(data));
                },
                targets: include.indexOf("t_exptime"),
            },
            // append mission and instrument name to save horizontal space
            {
                targets: include.indexOf("obs_collection"),
                render: function (data, type, row) {
                    return data + " - " + row.instrument_name;
                },
            },
            // hide instrument name column
            {
                targets: include.indexOf("instrument_name"),
                visible: false,
            },
        ];
    }
    getHostname() {
        var hostname;
        switch (window.location.hostname) {
            case "localhost":
            case "z.mastdev.stsci.edu":
                hostname = "mastdev.stsci.edu";
                break;
            case "z.masttest.stsci.edu":
                hostname = "masttest.stsci.edu";
                break;
            case "z.mastperf.stsci.edu":
                hostname = "mastperf.stsci.edu";
                break;
            case "z.mast.stsci.edu":
                hostname = "mast.stsci.edu";
                break;
            }
        return hostname;
    }
    getApi() {
        const ra = Number(ZmastLocalStorage.getRA());
        const dec = Number(ZmastLocalStorage.getDec());
        const caom_request = CAOM_REQUEST_TEMPLATE;
        caom_request.params.position = ra + ", " + dec + ", 0.0";
        const hostname = this.getHostname();
        return "https://" + hostname + "/portal/Mashup/Mashup.asmx/invoke?request=" + encodeURI(JSON.stringify(caom_request));
    }
    setToolTips() {
        $("#results thead th").each(function () {
            var tooltipText = $(this).text();
            $(this).attr("title", tooltipText);
            //$(this).attr("data-placement", "top");
        });

        /* Apply the tooltips */
        $("#results thead th[title]").tooltip({
            container: "body",
        });
    }
    getPostData() {
        return {};
    }
    getHttpMethod() {
        return "GET";
    }
}

module.exports = CaomSearchResults;
