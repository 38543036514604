const Catalog = require("./catalog.js");

class ObjectNameLookup {
    static lookup(target) {
        const value = $.ajax({
            url: ObjectNameLookup.getUrl(target),
            async: false
        }).responseText;
        return JSON.parse(value);
    }
    static getCount(target) {
        const results = ObjectNameLookup.lookup(target);
        if ("data" in results) {
            return results.data.length;
        } else {
            return 0;
        }
    }
    static getFirstObject(target) {
        const results = ObjectNameLookup.lookup(target);
        if ("data" in results && results.data.length > 0) {
            return results.data[0];
        } else {
            return null;
        }
    }
    static getUrl(target) {
        const survey = Catalog.getSurvey();
        return "/api/v0.1/object_lookup/" + survey + "/?name=" + encodeURIComponent(target);
    }
}
module.exports = ObjectNameLookup;
