class ErrorMessage {
    static setMessage(message) {
        $("#error-message").html(message);
        $("#error-message").removeAttr("hidden");
    }
    static setNoRecordsMessage(message) {
        $("#error-message").html(message);
        $("#error-message").removeAttr("hidden");
        $("#error-message").css({ color: "white", "background-color": "#00617e", "text-align": "center", "font-size": "30px", border: "none" });
        $(".horizontal-scroll").hide();
    }
}
module.exports = ErrorMessage;
