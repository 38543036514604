//TODO: add correct links here
const COLUMN_DESCRIPTION_LINKS = {
    ALL_MASTER_VIEW: { description: "View ALL Table", link: "/columns/all-fields.html" },
    EGS_MASTER_VIEW: { description: "View EGS Table", link: "/columns/all-fields.html" },
    COSMOS_MASTER_VIEW: { description: "View COSMOS Table", link: "/columns/all-fields.html" },
    CANDELS_MASTER_VIEW: { description: "View CANDELS Table", link: "/columns/candels-fields.html" },
    GOODS_MULTIBAND_VIEW: { description: "View GOODS Multiband Table", link: "/columns/goods-fields.html" },
    GOODS_MASS_VIEW: { description: "View GOODS Mass Table", link: "/columns/goods-fields.html" },
    UDS_MULTIBAND_VIEW: { description: "View UDS Multiband Table", link: "/columns/uds-fields.html" },
    UDS_MASS_VIEW: { description: "View UDS Mass Table", link: "/columns/uds-fields.html" }
};

class RadioSelector {
    // when user clicks on a RadioSelector button, it becomes active and other buttons become inactive
    static init() {
        RadioSelector.activateAll();
        RadioSelector.setLinkToDocumentation();
    }
    static activateAll() {
        $(".radio-group label").on("click", function() {
            var isDisabled = $(this).hasClass("disabled");
            if (!isDisabled) {
                $(this)
                    .addClass("active")
                    .siblings()
                    .removeClass("active");
            }
        });
    }
    static setLinkToDocumentation() {
        /*
        var description = COLUMN_DESCRIPTION_LINKS.CANDELS_MASTER_VIEW;
        var region = Region.getRegion();
        if (region === "all") {
            description = COLUMN_DESCRIPTION_LINKS.ALL_MASTER_VIEW;
        }
        if (region === "goods-north" || region === "goods-south") {
            description = COLUMN_DESCRIPTION_LINKS.GOODS_MASS_VIEW;
        }
        if (region === "uds") {
            description = COLUMN_DESCRIPTION_LINKS.UDS_MASS_VIEW;
        }
        if (region === "cosmos") {
            description = COLUMN_DESCRIPTION_LINKS.COSMOS_MASTER_VIEW;
        }
        if (region === "egs") {
            description = COLUMN_DESCRIPTION_LINKS.EGS_MASTER_VIEW;
        }
        var description = { link: "/documentation.html", description: "Z MAST Docs" };
        var link = "<a id='link-to-column-description' target='_blank' href='" + description.link + "' >" + description.description + " </a>";
        $("#link-to-column-description-container").html(link);
        */
    }
}
module.exports = RadioSelector;
