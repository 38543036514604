const Metadata = require("./metadata.js");
const debounce = require("./debounce.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const DELAY_MILLISECONDS = 500;
const NUMERIC_TYPES = ["long", "double", "int"];
const Catalog = require("./catalog.js");

const RANGE_INPUT = `<input type="text" class="comparison-value form-control lower-bound" aria-label="value"/>
<label class="col-form-label col-1 ml-2 mr-1">to</label>
<input type="text" class="comparison-value form-control upper-bound" aria-label="value"/>`;
const SINGLE_INPUT = `<input type="text" class="form-control comparison-value" aria-label="value"/>`;

//If this is called several times in less than a second, it will be executed only once.
var loadSearchConditions = debounce(function () {
    SearchConditions.reload();
}, DELAY_MILLISECONDS);

class SearchConditions {
    static init() {
        SearchConditions.initButtons();
        $("#search-conditions-container").on("change", ".comparison-operator", SearchConditions.onChangeOperator.bind(this));
    }
    static initButtons() {
        $("#add-condition").click(function () {
            SearchConditions.addRow();
        });
        $("#remove-condition-0").click(function () {
            SearchConditions.removeRow(this);
        });
    }
    static onChangeOperator(event) {
        const $target = $(event.target);
        const inputArea = $target.parent().parent().find(".search-conditions-input-area");
        const prevVal = $target.data("val");
        if ($target.val() === "range") {
            inputArea.html(RANGE_INPUT);
        } else if (prevVal === "range" || !prevVal) {
            inputArea.html(SINGLE_INPUT);
        }
        $target.data("val", $target.val());
    }
    static load() {
        loadSearchConditions();
    }
    //get list of columns from METADATA_URL, populate dropdown
    static reload() {
        var listOfColumns = "";
        const self = this;
        $.getJSON(Metadata.getUrl(), function (result) {
            $("#provide-radius").text("Please provide a radius.");
            $("#provide-radius").hide();
            $.each(result, function (index, record) {
                listOfColumns += "<option data-db-type='" + record.datatype + "'>" + record.column_name + "</option>";
            });
            listOfColumns += "</div>";
            $("#search-condition-columns").html(listOfColumns);
            self.initFromStorage();
        })
            .done(function () {
                // if user switches survey, region or catalog, remove all search conditions
                const catalog = Catalog.getCatalog();
                //console.log("reload() previous: " + SearchConditions.previousCatalog + " current: " + catalog);
                if (SearchConditions.previousCatalog !== undefined && SearchConditions.previousCatalog !== catalog) {
                    SearchConditions.removeAll();
                }
                SearchConditions.previousCatalog = catalog;
            })
            .fail(function () {
                console.error("Unable to load column names and descriptions");
                $("#provide-radius").text("Unable to load column names and descriptions");
                $("#provide-radius").show();
            });
    }
    static addRow() {
        SearchConditions.rowCount++;
        if (SearchConditions.rowCount === 1) {
            $("#search-conditions-row").css("display", "flex");
            //reset defaults
            $(".comparison-operator").prop("selectedIndex", 0);
            $(".select-column-name").prop("selectedIndex", 0);
            $(".comparison-value").val("");
            //if RANGE_INPUT was in place before, must replace it with SINGLE_INPUT
            const row = $("#search-conditions-row");
            const inputArea = row.find(".search-conditions-input-area");
            inputArea.html(SINGLE_INPUT);
        } else {
            const elementId = "#remove-condition-" + (SearchConditions.rowCount - 1);
            const newRow = $("#search-conditions-row").clone();
            newRow.find(".comparison-operator").val("equals");
            const inputArea = newRow.find(".search-conditions-input-area");
            inputArea.html(SINGLE_INPUT);
            //newRow.find(".search-conditions-input-area").html(SINGLE_INPUT);
            newRow.find("button").prop("id", elementId);
            const styles = {
                "font-size": "28px",
                "padding-left": "16px",
                "padding-right": "16px",
                "padding-top": 0,
            };
            newRow.find("button").css(styles);
            newRow.find("button").click(function () {
                SearchConditions.removeRow(this);
            });
            newRow.appendTo("#search-conditions-container");
        }
        const SearchButton = require("./search-button.js");
        SearchButton.enable();
    }
    static removeRow(element) {
        SearchConditions.rowCount = Math.max(--SearchConditions.rowCount, 0);
        if (SearchConditions.rowCount === 0) {
            $("#search-conditions-row").css("display", "none");
        } else {
            $(element).closest(".row").remove();
        }
    }
    // remove search conditions
    static empty() {
        const count = SearchConditions.rowCount;
        for (var index = 0; index < count; index++) {
            $(".search-conditions-input-area:eq(" + index + ") .comparison-value").val("");
        }
    }
    // remove search conditions from UI
    static removeAll() {
        for (var index = SearchConditions.rowCount - 1; index >= 0; index--) {
            SearchConditions.removeRow(".select-column-name:eq(" + index + ")");
        }
    }
    static save() {
        var record;
        const recordList = [];
        var column;
        var operator;
        var value;
        var range = {};
        const count = SearchConditions.rowCount;
        for (var index = 0; index < count; index++) {
            value = $(".search-conditions-input-area:eq(" + index + ") .comparison-value").val();
            if (value !== "") {
                column = $(".select-column-name:eq(" + index + ") option:selected").text();
                operator = $(".comparison-operator:eq(" + index + ") option:selected").text();
                if (operator === "range") {
                    range.lowerBound = $(".search-conditions-input-area:eq(" + index + ") .comparison-value.lower-bound").val();
                    range.upperBound = $(".search-conditions-input-area:eq(" + index + ") .comparison-value.upper-bound").val();
                    record = { column, operator, range };
                } else {
                    record = { column, operator, value };
                }
                recordList.push(record);
            }
        }
        ZmastLocalStorage.setSearchConditionsForUi(recordList);
    }
    static isValid() {
        var column;
        var operator;
        var value;
        var dbType;
        var ret = true;
        const count = SearchConditions.rowCount;
        for (var index = 0; index < count; index++) {
            value = $(".search-conditions-input-area:eq(" + index + ") .comparison-value").val();
            if (value !== "") {
                column = $(".select-column-name:eq(" + index + ") option:selected").text();
                operator = $(".comparison-operator:eq(" + index + ") option:selected").text();
                dbType = $(".select-column-name:eq(" + index + ") option:selected").attr("data-db-type");
                if (NUMERIC_TYPES.indexOf(dbType) >= 0 && isNaN(value) && operator !== "is like") {
                    var message = "Value " + value + " must be a number.";
                    $("#invalid-value-message").text(message);
                    $("#invalid-value").show();
                    $("button.close").click(function () {
                        $("#invalid-value").hide();
                    });
                    ret = false;
                }
            }
        }
        return ret;
    }
    static initFromStorage() {
        //when the page initializes, this is called several times. Thus it is designed and implemented to be idempotent.
        const recordList = ZmastLocalStorage.getSearchConditionsForUi();
        if (recordList === null || recordList.length === 0) {
            //hide first row
            $("#search-conditions-row").css("display", "none");
            SearchConditions.rowCount = 0;
        } else {
            //get number of search condition rows currently displayed
            const count = $(".sc-row-class").length;
            var record;
            SearchConditions.rowCount = 0;
            if (recordList === null || recordList.length === 0) {
                //hide first row
                $("#search-conditions-row").css("display", "none");
            } else {
                //unhide the first row
                $("#search-conditions-row").css("display", "flex");
                SearchConditions.rowCount++;
            }
            //add a number of search condition rows if not already in place
            for (var index = count; index < recordList.length; index++) {
                record = recordList[index];
                if ((record.value !== "" && record.value !== undefined) || record.range !== undefined) {
                    SearchConditions.addRow();
                }
            }
            //init values in cells
            for (index = 0; index < recordList.length; index++) {
                record = recordList[index];
                if (record.value !== "") {
                    $(".select-column-name:eq(" + index + ")").val(record.column);
                    $(".comparison-operator:eq(" + index + ")").val(record.operator);
                    if (record.operator === "range") {
                        $(".search-conditions-input-area:eq(" + index + ")").html(RANGE_INPUT);
                        $(".search-conditions-input-area:eq(" + index + ") .comparison-value.lower-bound").val(record.range.lowerBound);
                        $(".search-conditions-input-area:eq(" + index + ") .comparison-value.upper-bound").val(record.range.upperBound);
                    } else {
                        $(".search-conditions-input-area:eq(" + index + ")").html(SINGLE_INPUT);
                        $(".search-conditions-input-area:eq(" + index + ") .comparison-value").val(record.value);
                    }
                }
            }
            SearchConditions.rowCount = recordList.length;
        }
    }
    static getSearchConditions() {
        const listOfConditions = [];
        var index;
        var condition;
        const length = SearchConditions.getRowCount();
        for (index = 0; index < length; index++) {
            condition = {};
            condition.column = $(".select-column-name:eq(" + index + ")").val();
            condition.operator = $(".comparison-operator:eq(" + index + ")").val();

            if (condition.operator === "range") {
                condition.lowerBound = $(".search-conditions-input-area:eq(" + index + ") .comparison-value.lower-bound").val();
                condition.upperBound = $(".search-conditions-input-area:eq(" + index + ") .comparison-value.upper-bound").val();
            } else {
                condition.value = $(".search-conditions-input-area:eq(" + index + ") .comparison-value").val();
            }
            listOfConditions.push(condition);
        }
        return SearchConditions.convertToApi(listOfConditions);
    }
    static convertToApi(listOfConditions) {
        if (listOfConditions.length === 0) {
            return null;
        }
        var operator;
        var condition;
        const result = {};
        for (var index = 0; index < listOfConditions.length; index++) {
            condition = listOfConditions[index];
            if (condition.value === null || condition.value === "") {
                continue; //ignore this record
            }
            if (condition.operator === "range") {
                result[condition.column + ".gte"] = condition.lowerBound;
                result[condition.column + ".lte"] = condition.upperBound;
            } else {
                switch (condition.operator) {
                    case "equals":
                        operator = "";
                        break;
                    case "not equal to":
                        operator = ".noteq";
                        break;
                    case "is less than":
                        operator = ".lt";
                        break;
                    case "is less than or equal to":
                        operator = ".lte";
                        break;
                    case "is greater than":
                        operator = ".gt";
                        break;
                    case "is greater than or equal to":
                        operator = ".gte";
                        break;
                    case "is like":
                        operator = ".like";
                        break;
                    default:
                        operator = "unknown operator";
                }
                result[condition.column + operator] = condition.value;
            }
        }
        return result;
    }
    static getRowCount() {
        return SearchConditions.rowCount;
    }
}

module.exports = SearchConditions;
