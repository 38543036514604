const ObjectInfoCandels = require("./object-info-candels.js");
const ObjectInfoGoods = require("./object-info-goods.js");
const ObjectInfoDeepspace = require("./object-info-deepspace.js");
const ObjectInfo3Dhst = require("./object-info-3dhst.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");

class ObjectInfoFactory {
    getClass() {
        let objectInfo = null;
        const survey = ZmastLocalStorage.getSurvey();
        switch (survey.toLowerCase()) {
            case "candels":
                objectInfo = new ObjectInfoCandels();
                break;
            case "goods":
                objectInfo = new ObjectInfoGoods();
                break;
            case "deepspace":
                objectInfo = new ObjectInfoDeepspace();
                break;
            case "3dhst":
                objectInfo = new ObjectInfo3Dhst();
                break;
            default:
                console.error("Unknown survey: " + survey);
                break;
        }
        return objectInfo;
    }
}
module.exports = ObjectInfoFactory;
