const Catalog = require("./catalog.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");

const CANDELS_ONLY_REGIONS = ["EGS", "UDS", "COSMOS"];

class Region {
    static init() {
        const region = ZmastLocalStorage.getRegion();
        if (Catalog.getSurvey() === "goods" && CANDELS_ONLY_REGIONS.includes(region)) {
            //region = "ALL";
        }
        $("input[name=region][value=" + region + "]").click();
        $("#region").on("click", function () {
            Catalog.display();
        });
    }
}
module.exports = Region;
