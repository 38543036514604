const LS_KEY = {
    SEARCH_TYPE: "zmast.search-type",
    RA: "zmast.ra",
    DEC: "zmast.dec",
    SEARCH_STRING: "zmast.search-string",
    TARGETNAME: "zmast.target-name",
    TARGETMODE: "zmast.target-mode",
    RADIUS_API: "zmast.radius-api",
    RADIUS_UI: "zmast.radius-ui",
    RADIUS_UNITS: "zmast.radius-units",
    SURVEY: "zmast.survey",
    REGION: "zmast.region",
    CATALOG: "zmast.catalog",
    SEARCH_CONDITIONS_API: "zmast.search-conditions-api",
    SEARCH_CONDITIONS_UI: "zmast.search-conditions-ui",
    OBJ_ID: "zmast.objid"
};

class ZmastLocalStorage {
    static getSurvey() {
        return localStorage.getItem(LS_KEY.SURVEY);
    }
    static setSurvey(value) {
        localStorage.setItem(LS_KEY.SURVEY, value);
    }
    static getRegion() {
        return localStorage.getItem(LS_KEY.REGION);
    }
    static setRegion(value) {
        localStorage.setItem(LS_KEY.REGION, value);
    }
    static getCatalog() {
        return localStorage.getItem(LS_KEY.CATALOG);
    }
    static setCatalog(value) {
        localStorage.setItem(LS_KEY.CATALOG, value);
    }

    static getObjectId() {
        return localStorage.getItem(LS_KEY.OBJ_ID);
    }
    static setObjectId(value) {
        localStorage.setItem(LS_KEY.OBJ_ID, value);
    }

    static getSearchType() {
        return localStorage.getItem(LS_KEY.SEARCH_TYPE);
    }
    static setSearchType(value) {
        localStorage.setItem(LS_KEY.SEARCH_TYPE, value);
    }

    static getSearchString() {
        return localStorage.getItem(LS_KEY.SEARCH_STRING);
    }
    static setSearchString(value) {
        localStorage.setItem(LS_KEY.SEARCH_STRING, value);
    }

    static getTargetName() {
        return localStorage.getItem(LS_KEY.TARGETNAME);
    }
    static setTargetName(value) {
        localStorage.setItem(LS_KEY.TARGETNAME, value);
    }
    static getTargetMode() {
        return localStorage.getItem(LS_KEY.TARGETMODE);
    }
    static setTargetMode(value) {
        localStorage.setItem(LS_KEY.TARGETMODE, value);
    }

    static getRA() {
        return localStorage.getItem(LS_KEY.RA);
    }
    static setRA(value) {
        localStorage.setItem(LS_KEY.RA, value);
    }
    static getDec() {
        return localStorage.getItem(LS_KEY.DEC);
    }
    static setDec(value) {
        localStorage.setItem(LS_KEY.DEC, value);
    }
    static getRadiusForApi() {
        return localStorage.getItem(LS_KEY.RADIUS_API);
    }
    static setRadiusForApi(value) {
        localStorage.setItem(LS_KEY.RADIUS_API, value);
    }
    static getRadiusForUi() {
        return localStorage.getItem(LS_KEY.RADIUS_UI);
    }
    static setRadiusForUi(value) {
        localStorage.setItem(LS_KEY.RADIUS_UI, value);
    }
    static getRadiusUnits() {
        return localStorage.getItem(LS_KEY.RADIUS_UNITS);
    }
    static setRadiusUnits(value) {
        localStorage.setItem(LS_KEY.RADIUS_UNITS, value);
    }
    static getSearchConditionsForApi() {
        const localStoreString = localStorage.getItem(LS_KEY.SEARCH_CONDITIONS_API);
        if (localStoreString === null) {
            return null;
        }
        return JSON.parse(localStoreString);
    }
    static setSearchConditionsForApi(recordList) {
        localStorage.setItem(LS_KEY.SEARCH_CONDITIONS_API, JSON.stringify(recordList));
    }
    static getSearchConditionsForUi() {
        const localStoreString = localStorage.getItem(LS_KEY.SEARCH_CONDITIONS_UI);
        if (localStoreString === null) {
            return null;
        }
        return JSON.parse(localStoreString);
    }
    static setSearchConditionsForUi(recordList) {
        localStorage.setItem(LS_KEY.SEARCH_CONDITIONS_UI, JSON.stringify(recordList));
    }
}
module.exports = ZmastLocalStorage;
