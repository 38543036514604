const Constants = {
    Z_MAST_OBJECT_PAGE: "/object.html",
    Z_MAST_COORDINATES_PAGE: "/coordinates.html",
    Z_MAST_DATA_PAGE: "/data-description.html",
    Z_MAST_HLSP_PAGES: ["/hlsp-candels.html", "/hlsp-goods.html", "/hlsp-deepspace.html", "/hlsp-3dhst.html"],
    DATA_URL: "/api/v0.1/",
    UI_MESSAGES_ZMAST: { LOADING: "Loading Z Mast data ...", DOWNLOADING: "Downloading Z Mast data..." },
    UI_MESSAGES_CAOM: { LOADING: "Loading observational data ...", DOWNLOADING: "Downloading observational data..." },
    NO_IMAGES_MESSAGE: "<h3>No images available for this target</h3>",
    NO_IMAGES_MESSAGE_DEEPSPACE: "<h3>Image cutouts will be available for the HFF-DEEPSPACE survey May 2021</h3>",
    NO_IMAGES_MESSAGE_3DHST: "<h3>Image cutouts will be available for the 3D-HST survey May 2021</h3>",
    Z_MAST_HLSP_MAP: [
        { page: "/hlsp-candels.html", hlsp: "CANDELS" },
        { page: "/hlsp-goods.html", hlsp: "GOODS" },
        { page: "/hlsp-deepspace.html", hlsp: "DEEPSPACE" },
        { page: "/hlsp-3dhst.html", hlsp: "3DHST" },
    ],
    REQUEST_COMPLETE: 4,
};
module.exports = Constants;
