const ZmastLocalStorage = require("./zmast-local-storage.js");

class ObjectIdLookup {
    static lookup(target) {
        const value = $.ajax({
            url: ObjectIdLookup.getUrl(target),
            async: false
        }).responseText;
        return JSON.parse(value);
    }
    static getFirstObject(target) {
        const results = ObjectIdLookup.lookup(target);
        if ("data" in results && results.data.length > 0) {
            return results.data[0];
        } else {
            return null;
        }
    }

    static getUrl(target) {
        return "/api/v0.1/object_lookup/" + ZmastLocalStorage.getSurvey() + "/?id=" + encodeURIComponent(target);
    }
}
module.exports = ObjectIdLookup;
