const ObjectInfo = require("./object-info.js");
const toFixed = require("./number-formatter.js");

class ObjectInfoGoods extends ObjectInfo {
    getObjectInfo(json) {
        var objectInfoData;
        if (json.data[0]) {
            const record = json.data[0];
            objectInfoData = {
                id: record.ID,
                ra: record.RA,
                dec: record.DEC,
                z_spec: record.ZSPEC,
                z_peak: record.ZBEST,
                lmass: record.LMASS,
            };
        } else {
            objectInfoData = {
                id: "",
                ra: "",
                dec: "",
                z_spec: "",
                z_peak: "",
                lmass: "",
            };
        }
        return objectInfoData;
    }
    getPhotometry(json) {
        var photometryData;
        if (json.data[0]) {
            const record = json.data[0];
            photometryData = {
                ACS_F435W_MAG: record.ACS_F435W_MAG,
                ACS_F606W_MAG: record.ACS_F606W_MAG,
                ACS_F775W_MAG: record.ACS_F775W_MAG,
                ACS_F850LP_MAG: record.ACS_F850LP_MAG,
            };
        } else {
            photometryData = {};
        }
        return photometryData;
    }
    getMorphology(json) {
        var morphologyData;
        if (json.data[0]) {
            const record = json.data[0];
            //            Class_star, FWHM_IMAGE_F850LP, FLUX_RADIUS_2_F850LP
            morphologyData = {
                kron_radius: record.KRON_RADIUS,
                a_image: record.A_IMAGE,
                b_image: record.B_IMAGE,
                fwhm_image_f850lp: record.FWHM_IMAGE_F850LP,
                flux_radius_2_f850lp: record.FLUX_RADIUS_2_F850LP,
                class_star: record.Class_star,
            };
        } else {
            morphologyData = {};
        }
        return morphologyData;
    }
    display(json) {
        $("#object-info-goods").show();
        $("#object-info-candels").hide();
        $("#object-info-deepspace").hide();
        $("#object-info-3dhst").hide();
        super.display(json);
    }
    displayObjectInfo(objectInfoData) {
        $("#id-goods strong").text(objectInfoData.id);
        $("#ra-goods strong").text(objectInfoData.ra);
        $("#dec-goods strong").text(objectInfoData.dec);
    }
    displayPhotometry(photometryData) {
        $("#ACS_F435W_goods .value").text(toFixed(photometryData.ACS_F435W_MAG, 4));
        $("#ACS_F606W_goods .value").text(toFixed(photometryData.ACS_F606W_MAG, 4));
        $("#ACS_F775W_goods .value").text(toFixed(photometryData.ACS_F775W_MAG, 4));
        $("#ACS_F850LP_goods .value").text(toFixed(photometryData.ACS_F850LP_MAG, 4));
    }
    displayMorphology(morphologyData) {
        $("#kron_radius_goods .value").text(morphologyData.kron_radius);
        $("#a_image_goods .value").text(morphologyData.a_image);
        $("#b_image_goods .value").text(morphologyData.b_image);
        $("#fwhm_image_f850lp .value").text(morphologyData.fwhm_image_f850lp);
        $("#class_star .value").text(morphologyData.class_star);
        $("#flux_radius_2_f850lp .value").text(toFixed(morphologyData.flux_radius_2_f850lp, 4));
    }
}

module.exports = ObjectInfoGoods;
