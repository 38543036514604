const ObjectName = require("./object-name.js");
const ObjectInfoData = require("./object-info-data.js");
const DownloadButtons = require("./download-buttons.js");
const PlotTabs = require("./plot-tabs.js");
const ImageKeyHandler = require("./image-key-handler.js");
const StaticImages = require("./static-images.js");
const Constants = require("./constants.js");
const SearchResultsFactory = require("./search-results-factory.js");
const ZmastParameterManager = require("./zmast-parameter-manager.js");
const SearchParamsMessage = require("./search-params-message.js");
const ErrorMessage = require("./error-message.js");
const Spectra = require("./spectra.js");
const SedSettings = require("./sed-settings.js");
const PowerTips = require("./powertips.js");

$(document).ready(function () {
    try {
        if (Constants.Z_MAST_COORDINATES_PAGE === window.location.pathname) {
            ZmastParameterManager.checkCoordsPageParams();
            let searchResults = SearchResultsFactory.getClass();
            searchResults.getTarget();
            DownloadButtons.init();
        }
        if (Constants.Z_MAST_OBJECT_PAGE === window.location.pathname) {
            ZmastParameterManager.checkObjectParams();
            let searchResults = SearchResultsFactory.getClass();
            searchResults.getTarget();
            DownloadButtons.init();
            let objectInfoData = new ObjectInfoData();
            objectInfoData.getObject();
            ObjectName.display();
            PlotTabs.init();
            PlotTabs.displaySedPlot();
            StaticImages.init();
            ImageKeyHandler.init();
            Spectra.init();
            SedSettings.init();
            PowerTips.initSedTooltips();
        }
    } catch (exception) {
        console.error(exception);
        ErrorMessage.setMessage(exception);
    }
});
