const TabsOfSearchTypes = require("./tabs-of-search-types.js");
const SearchConditions = require("./search-conditions.js");
const Survey = require("./survey.js");
const Catalog = require("./catalog.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");

//TODO: create class FormValueRestorer
class UserInterfaceCustomizations {
    static enableTooltips() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    init() {
        this.initFormValues();
        //radius cannot be blank OR negative
        $("#provide-radius").hide();
        $("#search-radius").focusout(function() {
            if ("coordinates" === TabsOfSearchTypes.getSearchType()) {
                const radius = $(this).val();
                if (radius && radius >= 0) {
                    $("#provide-radius").hide();
                } else {
                    $("#provide-radius").show();
                    $(this).focus();
                }
            }
        });
        Survey.disableButtons();
        CoordinatesModule.enableResolveButton();
    }
    static saveFormValues() {
        CoordinatesModule.saveFormValues();
        ZmastLocalStorage.setSearchType(TabsOfSearchTypes.getSearchType());
        ZmastLocalStorage.setSurvey(Catalog.getSurvey());
        ZmastLocalStorage.setRegion(Catalog.getRegion());
        ZmastLocalStorage.setCatalog(Catalog.getCatalog());
        SearchConditions.save();
    }
    initFormValues() {
        //restore values from local storage
        const searchType = ZmastLocalStorage.getSearchType();
        if (searchType === null || searchType === "parameter") {
            $("#tab-button-parameter").click();
        } else {
            if (searchType === "coordinates") {
                $("#tab-button-coordinates").click();
            } else {
                $("#tab-button-object").click();
            }
        }
    }
}
module.exports = UserInterfaceCustomizations;
