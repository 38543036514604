// Some telescope instruments included here:
// Canada France Hawaii Telescope (CFHT) MEGACAM
// SUBARU SUPRIMECAM
const DESIRED_ORDER = [
    { instrument: "UVIS", filter: "F225W" },
    { instrument: "WFC3", filter: "F225W" },
    { instrument: "UVIS", filter: "F275W" },
    { instrument: "WFC3", filter: "F275W" },
    { instrument: "UVIS", filter: "F336W" },
    { instrument: "WFC3", filter: "F336W" },
    { instrument: "LBC", filter: "U" },
    { instrument: "WFI", filter: "U38" },
    { instrument: "KPNO", filter: "U" },
    { instrument: "CTIO", filter: "U" },
    { instrument: "VIMOS", filter: "U" },
    { instrument: "CFHT", filter: "U" },
    //Nicholas U. Mayall Telescope- Mosaic
    { instrument: "MOSAIC", filter: "U" },
    { instrument: "MEGACAM", filter: "U.MP9301" },
    { instrument: "UVIS", filter: "F390W" },
    { instrument: "WFC3", filter: "F390W" },
    { instrument: "SUPRIMECAM", filter: "IA427" },
    { instrument: "ACS", filter: "F435W" },
    { instrument: "SUPRIMECAM", filter: "IA445" },
    { instrument: "SUBARU", filter: "B" },
    { instrument: "SUPRIMECAM", filter: "B" },
    { instrument: "WFI", filter: "B" },
    { instrument: "SUPRIMECAM", filter: "IA464" },
    { instrument: "ACS", filter: "F475W" },
    { instrument: "SUPRIMECAM", filter: "IA484" },
    { instrument: "LRIS", filter: "G" },
    { instrument: "CFHT", filter: "g" },
    { instrument: "MEGACAM", filter: "G.MP9401" },
    { instrument: "WFI", filter: "V" },
    { instrument: "SUPRIMECAM", filter: "IA505" },
    { instrument: "SUPRIMECAM", filter: "IA527" },
    { instrument: "SUPRIMECAM", filter: "IA550" },
    { instrument: "ACS", filter: "F555W" },
    { instrument: "SUPRIMECAM", filter: "IA574" },
    { instrument: "SUPRIMECAM", filter: "IA598" },
    { instrument: "SUBARU", filter: "V" },
    { instrument: "SUPRIMECAM", filter: "V" },
    { instrument: "ACS", filter: "F606W" },
    { instrument: "SUBARU", filter: "rp" },
    { instrument: "SUPRIMECAM", filter: "RP" },
    { instrument: "CFHT", filter: "r" },
    { instrument: "SUPRIMECAM", filter: "IA624" },
    { instrument: "ACS", filter: "F625W" },
    { instrument: "SUPRIMECAM", filter: "IA651" },
    { instrument: "SUPRIMECAM", filter: "IA679" },
    { instrument: "VIMOS", filter: "R" },
    { instrument: "SUBARU", filter: "Rc" },
    { instrument: "SUPRIMECAM", filter: "RC" },
    { instrument: "WFI", filter: "Rc" },
    { instrument: "LRIS", filter: "Rs" },
    { instrument: "MEGACAM", filter: "R.MP9601" },
    { instrument: "CFHT", filter: "i" },
    { instrument: "SUBARU", filter: "ip" },
    { instrument: "SUPRIMECAM", filter: "IP" },
    { instrument: "SUPRIMECAM", filter: "IC" },
    { instrument: "MEGACAM", filter: "I.MP9701" },
    { instrument: "SUPRIMECAM", filter: "IA709" },
    { instrument: "SUPRIMECAM", filter: "IA738" },
    { instrument: "SUPRIMECAM", filter: "IA767" },
    { instrument: "ACS", filter: "F775W" },
    { instrument: "SUPRIMECAM", filter: "IA797" },
    { instrument: "ACS", filter: "F814W" },
    { instrument: "SUPRIMECAM", filter: "IA827" },
    //MPG/ESO Telescope - WFI
    { instrument: "WFI", filter: "ESO841" },
    { instrument: "WFI", filter: "ESO842" },
    { instrument: "WFI", filter: "ESO843" },
    { instrument: "WFI", filter: "ESO844" },
    { instrument: "WFI", filter: "ESO845" },
    { instrument: "SUPRIMECAM", filter: "IA856" },
    { instrument: "CFHT", filter: "z" },
    { instrument: "ACS", filter: "F850LP" },
    { instrument: "SUBARU", filter: "zp" },
    { instrument: "SUPRIMECAM", filter: "ZP" },
    { instrument: "MEGACAM", filter: "Z.MP9801" },
    { instrument: "WFC3", filter: "F098M" },
    { instrument: "WFI", filter: "I" },
    { instrument: "HAWKI", filter: "Y" },
    { instrument: "VISTA", filter: "Y" },
    { instrument: "WFC3", filter: "F105W" },
    { instrument: "NEWFIRM", filter: "J1" },
    { instrument: "WFC3", filter: "F110W" },
    { instrument: "NEWFIRM", filter: "J2" },
    { instrument: "WFC3", filter: "F125W" },
    { instrument: "VISTA", filter: "J" },
    { instrument: "ISAAC", filter: "J" },
    { instrument: "MOIRCS", filter: "J" },
    { instrument: "WIRCAM", filter: "J" },
    { instrument: "WFCAM", filter: "J" },
    { instrument: "UKIDSS", filter: "DR8_J" },
    { instrument: "NEWFIRM", filter: "J3" },
    { instrument: "WFC3", filter: "F140W" },
    { instrument: "WFC3", filter: "F160W" },
    { instrument: "NEWFIRM", filter: "H1" },
    { instrument: "WIRCAM", filter: "H" },
    { instrument: "MOIRCS", filter: "H" },
    { instrument: "VISTA", filter: "H" },
    { instrument: "WFCAM", filter: "H" },
    { instrument: "UKIDSS", filter: "DR8_H" },
    { instrument: "ISAAC", filter: "H" },
    { instrument: "NEWFIRM", filter: "H2" },
    { instrument: "SUBARU", filter: "Ks" },
    { instrument: "SUPRIMECAM", filter: "RS" },
    { instrument: "MOIRCS", filter: "Ks" },
    { instrument: "HAWKI", filter: "KS" },
    { instrument: "VISTA", filter: "Ks" },
    { instrument: "WIRCAM", filter: "K" },
    { instrument: "WIRCAM", filter: "KS" },
    { instrument: "MOSFIRE", filter: "KS" },
    { instrument: "NEWFIRM", filter: "K" },
    { instrument: "ISAAC", filter: "KS" },
    { instrument: "UKIDSS", filter: "DR8_K" },
    //United Kingdom Infrared Telescope (UKIRT) - WFCAM
    { instrument: "WFCAM", filter: "K" },
    { instrument: "WFCAM", filter: "Ks" },
    { instrument: "IRAC", filter: "IRAC1" },
    { instrument: "IRAC", filter: "CH1" },
    { instrument: "IRAC", filter: "IRAC2" },
    { instrument: "IRAC", filter: "CH2" },
    { instrument: "IRAC", filter: "IRAC3" },
    { instrument: "IRAC", filter: "CH3" },
    { instrument: "IRAC", filter: "IRAC4" },
    { instrument: "IRAC", filter: "CH4" },
];

class CutoutImageSorter {
    static getSortPosition(fileProps) {
        var sortPosition = -1;
        //translate "ACS-WFC" is synonymous with "ACS"
        var instrument = fileProps.instrument === "ACS-WFC" ? "ACS" : fileProps.instrument;
        for (var index = 0; index < DESIRED_ORDER.length; index++) {
            if (DESIRED_ORDER[index].instrument === instrument && DESIRED_ORDER[index].filter.toUpperCase() === fileProps.filter) {
                sortPosition = index;
                //console.log(DESIRED_ORDER[index].filter);
                break;
            }
        }
        if (sortPosition === -1) {
            console.log(fileProps);
            // Unknown observatories/filters start at 200 so that these do not appear before the items in DESIRED_ORDER list
            sortPosition = fileProps.observatory.charCodeAt(0) + 135;
        }
        return sortPosition;
    }
    static getSortOrder(a, b) {
        const startA = parseInt($(a).data("order"));
        const startB = parseInt($(b).data("order"));
        return startA - startB;
    }
    static sortFiguresByOrder() {
        $("#images-row").html($("#images-row figure").sort(CutoutImageSorter.getSortOrder));
    }
}
module.exports = CutoutImageSorter;
