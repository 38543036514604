const FormatConverter = require("./format-converter.js");
const Constants = require("./constants.js");
const SearchResultsFactory = require("./search-results-factory.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");

var bodyModalLoaded;

class Downloader {
    constructor() {
        this.searchResults = SearchResultsFactory.getClass();
    }
    download() {
        // create a blob of data based on the output dropdown and attach it to the document
        // options - an object containing perhaps a success/error callback
        const options = {};
        const self = this;
        const type = $("#sel-filetype").val();
        const api = this.searchResults.getApi(ZmastLocalStorage.getSurvey());
        const postData = this.searchResults.getPostData();
        if ("download" in HTMLAnchorElement.prototype) {
            const filetype = type == "votable" ? "xml" : type;
            const request = new XMLHttpRequest();
            request.addEventListener("readystatechange", function (e) {
                if (request.readyState === Constants.REQUEST_COMPLETE) {
                    const contentType = request.getResponseHeader("Content-Type");
                    const data = request.response;
                    $("body").loadingModal("hide");
                    bodyModalLoaded = true;
                    if (request.status == 200) {
                        const fileName = "z-mast-" + new Date().toLocaleDateString() + "." + filetype;
                        if (filetype === "tsv") {
                            self.extractData(data, filetype, contentType, options);
                        } else {
                            const blob = new Blob([data], { type: contentType });
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.target = "_blank";
                            link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(link.href);
                            if (options.success) {
                                options.success.call(self, fileName);
                                GaWrapper.writeGaEvent("Z MAST", "Download data", "Success");
                            }
                        }
                    } else {
                        if (options.error) {
                            options.error.call(self, request, request.status, request.statusText);
                            GaWrapper.writeGaEvent("Z MAST", "Download data", "Failure");
                        }
                    }
                }
            });

            if (!bodyModalLoaded) {
                // create new fullscreen modal if first time downloading
                $("body").loadingModal({
                    text: Constants.UI_MESSAGES_ZMAST.DOWNLOADING,
                    color: "#00617E",
                    backgroundColor: "#FFF",
                    animation: "wave",
                    opacity: 1,
                });
            } else {
                // reuse if this modal was simply hidden, ostensibly rare that anyone would do this
                $("body").loadingModal("show");
            }
            request.responseType = "blob";
            request.open("POST", api);
            request.setRequestHeader("Content-type", "application/json; charset=UTF-8");
            request.setRequestHeader("Accept", this.getAcceptHeader(type));
            request.send(JSON.stringify(postData));
        } else {
            // Download is not available in certain browsers
            // Instead, open in new window
            window.open(api);
            if (options.success) {
                options.success.call(self, self.messages.cutout_download_new_window);
                GaWrapper.writeGaEvent("Z MAST", "Download data", "Open in Window");
            }
        }
    }
    getAcceptHeader(type) {
        // Set the accept header based on the requested type
        var accept_header = "";
        if (type === "json") {
            accept_header = "application/json; charset=utf-8";
        } else if (type === "csv") {
            accept_header = "text/csv; charset=utf-8;";
        } else if (type === "votable") {
            accept_header = "application/votable; charset=utf-8";
        }
        return accept_header;
    }
    //CAOM API data comes back not as a JSON array, but as a JSON object. The JSON array we want is in the object's data property.
    extractData(data, filetype, contentType, options) {
        const reader = new FileReader();
        const self = this;
        // This fires after the blob has been read/loaded.
        reader.addEventListener("loadend", (e) => {
            const text = e.srcElement.result;
            const extracted = JSON.parse(text).data;
            const fileName = "z-mast-" + new Date().toLocaleDateString() + "." + filetype;
            var blob1;
            if (filetype === "json") {
                blob1 = new Blob([JSON.stringify(extracted)], { type: "octet/stream" });
            }
            if (filetype === "csv") {
                blob1 = new Blob([FormatConverter.convertJsonToCsv(extracted)], { type: "octet/stream" });
            }
            if (filetype === "tsv") {
                blob1 = new Blob([FormatConverter.convertJsonToTsv(extracted)], { type: "octet/stream" });
            }
            if (filetype === "xml") {
                //TODO: need to convert to XML
                blob1 = new Blob([JSON.stringify(extracted)], { type: "octet/stream" });
            }
            const blob2 = new Blob([blob1], { type: contentType });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob2);
            link.target = "_blank";
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
            if (options.success) {
                options.success.call(self, fileName);
                GaWrapper.writeGaEvent("Z Mast", "Download CAOM data", "Success");
            }
        });

        // Start reading the blob as text.
        reader.readAsText(data);
    }
}
module.exports = Downloader;
