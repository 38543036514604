const Catalog = require("./catalog.js");
const Constants = require("./constants.js");

class Metadata {
    static getUrl() {
        // TODO: catalog is (sometimes) not set on initial page load so this breaks
        return Constants.DATA_URL + Catalog.getSurvey() + "/metadata/" + Catalog.getCatalog() + ".json";
    }
}
module.exports = Metadata;
