class BackToTop {
    static init() {
        const backToTopButton = $("#zmast-back-to-top-button");
        backToTopButton.click(BackToTop.onBackToTop);
        $(window).scroll(BackToTop.onScrollDown);
    }
    static onBackToTop() {
        const backToTopButton = $("#zmast-back-to-top-button");
        backToTopButton.removeClass("is-active");
        backToTopButton.addClass("is-hidden");
    }
    static onScrollDown() {
        if ($(document).scrollTop() > BackToTop.DISTANCE_FROM_TOP) {
            const backToTopButton = $("#zmast-back-to-top-button");
            backToTopButton.addClass("is-active");
            backToTopButton.removeClass("is-hidden");
        }
    }
}
//Safari requires you to define these outside the class
BackToTop.DISTANCE_FROM_TOP = 500;

BackToTop.init();
module.exports = BackToTop;
