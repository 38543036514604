const Constants = require("./constants.js");

class SearchResultsFactory {
    static getClass() {
        const CaomSearchResults = require("./caom-search-results.js");
        const ZmastSearchResults = require("./zmast-search-results.js");
        let searchResults = null;
        if (window.location.pathname === Constants.Z_MAST_OBJECT_PAGE) {
            searchResults = new CaomSearchResults();
        }
        if (window.location.pathname === Constants.Z_MAST_COORDINATES_PAGE) {
            searchResults = new ZmastSearchResults();
        }
        return searchResults;
    }
}
module.exports = SearchResultsFactory;
