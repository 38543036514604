const SedSettingsStorage = require("./sed-settings-storage");
const PlotTabs = require("./plot-tabs.js");

const SED_DEFAULTS = { X_SCALING: "linear", Y_SCALING: "log", X_UNITS: "wavelength", Y_UNITS: "nu_f_nu" };

class SedSettings {
    static init() {
        $("#sed-settings-close").click(function () {
            SedSettingsStorage.setSedXScaling(SedSettings.getXScalingFromRadio());
            SedSettingsStorage.setSedYScaling(SedSettings.getYScalingFromRadio());
            SedSettingsStorage.setSedXUnits(SedSettings.getXUnitsFromRadio());
            SedSettingsStorage.setSedYUnits(SedSettings.getYUnitsFromRadio());
            SedSettingsStorage.setSedFileType(SedSettings.getFileTypeFromRadio());
            PlotTabs.displaySedPlot(true);
        });
        SedSettings.initSettingsFromLocalStorage();
    }
    static initSettingsFromLocalStorage() {
        //If value is empty in local storage, store default value in local storage.
        if (SedSettingsStorage.getSedXScaling() == null) {
            SedSettingsStorage.setSedXScaling(SED_DEFAULTS.X_SCALING);
        }
        if (SedSettingsStorage.getSedYScaling() == null) {
            SedSettingsStorage.setSedYScaling(SED_DEFAULTS.Y_SCALING);
        }
        if (SedSettingsStorage.getSedXUnits() == null) {
            SedSettingsStorage.setSedXUnits(SED_DEFAULTS.X_UNITS);
        }
        if (SedSettingsStorage.getSedYUnits() == null) {
            SedSettingsStorage.setSedYUnits(SED_DEFAULTS.Y_UNITS);
        }
        SedSettings.setXScalingRadio();
        SedSettings.setXUnitsRadio();
        SedSettings.setYScalingRadio();
        SedSettings.setYUnitsRadio();
        SedSettings.setFileTypeRadio();
    }

    static setXScalingRadio() {
        const x_scaling = SedSettingsStorage.getSedXScaling();
        if (x_scaling === null || x_scaling === "linear") {
            $("#x-linear").click();
        } else {
            if (x_scaling === "log") {
                $("#x-log").click();
            }
        }
    }
    static setXUnitsRadio() {
        const xUnits = SedSettingsStorage.getSedXUnits();
        if (xUnits === null || xUnits === "wavelength") {
            $("#x-unit-1").click();
        } else {
            if (xUnits === "frequency") {
                $("#x-unit-2").click();
            }
        }
    }

    static setYScalingRadio() {
        const yScaling = SedSettingsStorage.getSedYScaling();
        if (yScaling === null || yScaling === "linear") {
            $("#y-linear").click();
        } else {
            if (yScaling === "log") {
                $("#y-log").click();
            }
        }
    }
    static setYUnitsRadio() {
        const yUnits = SedSettingsStorage.getSedYUnits();
        switch (yUnits) {
            case "f_nu":
                $("#y-unit-1").click();
                break;
            case null:
            case "nu_f_nu":
                $("#y-unit-2").click();
                break;
            case "f_lambda":
                $("#y-unit-3").click();
                break;
            case "lambda_f_lambda":
                $("#y-unit-4").click();
                break;
            case "f_nu_ab":
                $("#y-unit-5").click();
                break;
        }
    }
    static setFileTypeRadio() {
        const type = SedSettingsStorage.getSedFileType();
        if (type === null || type === "csv") {
            $("#sed-csv").click();
        } else {
            if (type === "tsv") {
                $("#sed-tsv").click();
            } else {
                $("#sed-json").click();
            }
        }
    }
    static getXScalingFromRadio() {
        var x_scaling;
        if ($("#x-linear").parent().hasClass("active")) {
            x_scaling = "linear";
        }
        if ($("#x-log").parent().hasClass("active")) {
            x_scaling = "log";
        }
        return x_scaling;
    }
    static getXUnitsFromRadio() {
        var x_units;
        if ($("#x-unit-1").parent().hasClass("active")) {
            x_units = "wavelength";
        }
        if ($("#x-unit-2").parent().hasClass("active")) {
            x_units = "frequency";
        }
        return x_units;
    }

    static getYScalingFromRadio() {
        var y_scaling;
        if ($("#y-linear").parent().hasClass("active")) {
            y_scaling = "linear";
        }
        if ($("#y-log").parent().hasClass("active")) {
            y_scaling = "log";
        }
        return y_scaling;
    }
    static getYUnitsFromRadio() {
        var y_units;
        if ($("#y-unit-1").parent().hasClass("active")) {
            y_units = "f_nu";
        }
        if ($("#y-unit-2").parent().hasClass("active")) {
            y_units = "nu_f_nu";
        }
        if ($("#y-unit-3").parent().hasClass("active")) {
            y_units = "f_lambda";
        }
        if ($("#y-unit-4").parent().hasClass("active")) {
            y_units = "lambda_f_lambda";
        }
        if ($("#y-unit-5").parent().hasClass("active")) {
            y_units = "f_nu_ab";
        }
        return y_units;
    }
    static getFileTypeFromRadio() {
        var type;
        if ($("#sed-csv").parent().hasClass("active")) {
            type = "csv";
        }
        if ($("#sed-tsv").parent().hasClass("active")) {
            type = "tsv";
        }
        if ($("#sed-json").parent().hasClass("active")) {
            type = "json";
        }
        return type;
    }
}
module.exports = SedSettings;
