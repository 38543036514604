const Constants = require("./constants.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const ObjectIdLookup = require("./object-id-lookup.js");
const Catalog = require("./catalog.js");

const ZmastParameterNames = {
    SEARCH_TYPE: "search-type",
    RA: "ra",
    DEC: "dec",
    SEARCH_STRING: "search-string",
    TARGETNAME: "target-name",
    TARGETMODE: "target-mode",
    RADIUS_API: "radius-api",
    RADIUS_UI: "radius-ui",
    RADIUS_UNITS: "radius-units",
    SURVEY: "survey",
    REGION: "region",
    CATALOG: "catalog",
    SEARCH_CONDITIONS_API: "search-conditions-api",
    SEARCH_CONDITIONS_UI: "search-conditions-ui",
    OBJ_ID: "objid",
};
const VALID_SURVEYS = ["CANDELS", "GOODS", "DEEPSPACE", "3DHST"];

class ZmastParameterManager {
    // The object page needs to get the survey and the object ID
    // for example: survey=candels&objid=1100000004
    static getObjectParams() {
        const url = new URL(window.location.href);
        return { survey: url.searchParams.get(ZmastParameterNames.SURVEY).toUpperCase(), objid: url.searchParams.get(ZmastParameterNames.OBJ_ID) };
    }
    static checkObjectParams() {
        const params = ZmastParameterManager.getObjectParams();
        if (ZmastParameterManager.isSurveyValid(params.survey) && ZmastParameterManager.isObjectIdValid(params.objid)) {
            ZmastLocalStorage.setSurvey(params.survey.toLowerCase());
            ZmastLocalStorage.setObjectId(params.objid);
            const foundRecord = ObjectIdLookup.getFirstObject(params.objid);
            if (foundRecord) {
                ZmastLocalStorage.setRA(foundRecord.ra);
                ZmastLocalStorage.setDec(foundRecord.dec);
                ZmastLocalStorage.setTargetName(foundRecord.IAU_NAME);
            } else {
                throw "Object ID " + params.objid + " not found in " + params.survey;
            }
        } else {
            if (!ZmastParameterManager.isSurveyValid(params.survey)) {
                throw params.survey + " is not a survey supported by Z MAST";
            }
            if (!ZmastParameterManager.isObjectIdValid(params.objid)) {
                throw params.objid + " is not a valid object ID";
            }
        }
    }
    static goToObjectPage(survey, objid, newTab) {
        var url = new URL(window.location.origin + Constants.Z_MAST_OBJECT_PAGE);
        url.searchParams.set(ZmastParameterNames.SURVEY, survey);
        url.searchParams.set(ZmastParameterNames.OBJ_ID, objid);
        if (newTab) {
            window.open(url, "_blank");
        } else {
            window.location.href = url;
        }
    }

    // The coordinates page needs to get the catalog and the RA, DEC & Radius plus search conditions
    // for example: survey=candels&region=all&catalog=candels_sfr_view&ra=123.4&dec=52.1234&radius=0.0001&Flux_PACS_100.min=80000
    static getCoordsPageParams() {
        const url = new URL(window.location.href);
        return {
            survey: url.searchParams.get(ZmastParameterNames.SURVEY).toUpperCase(),
            region: url.searchParams.get(ZmastParameterNames.REGION).toUpperCase(),
            catalog: url.searchParams.get(ZmastParameterNames.CATALOG).toUpperCase(),
            searchConditions: url.searchParams.get(ZmastParameterNames.SEARCH_CONDITIONS_API),
            ra: url.searchParams.get(ZmastParameterNames.RA),
            dec: url.searchParams.get(ZmastParameterNames.DEC),
            radius: url.searchParams.get(ZmastParameterNames.RADIUS_API),
        };
    }
    static checkCoordsPageParams() {
        const params = ZmastParameterManager.getCoordsPageParams();
        if (
            ZmastParameterManager.isSurveyValid(params.survey) &&
            Catalog.isRegionValid(params) &&
            Catalog.isCatalogValid(params) &&
            ZmastParameterManager.isSearchConditionsValid(params.searchConditions)
        ) {
            ZmastLocalStorage.setSurvey(params.survey.toLowerCase());
            ZmastLocalStorage.setRegion(params.region.toLowerCase());
            ZmastLocalStorage.setCatalog(params.catalog.toLowerCase());
            ZmastLocalStorage.setSearchConditionsForApi(JSON.parse(params.searchConditions));
            if (
                ZmastParameterManager.isRaValid(params.ra) &&
                ZmastParameterManager.isDecValid(params.dec) &&
                ZmastParameterManager.isRadiusValid(params.radius)
            ) {
                ZmastLocalStorage.setRA(params.ra);
                ZmastLocalStorage.setDec(params.dec);
                ZmastLocalStorage.setRadiusForApi(params.radius);
            } else {
            }
        } else {
            if (!ZmastParameterManager.isSurveyValid(params.survey)) {
                throw params.survey + " is not a survey supported by Z MAST";
            }
            if (!Catalog.isRegionValid(params)) {
                throw params.region + " is not a valid region within " + params.survey;
            }
            if (!Catalog.isCatalogValid(params)) {
                throw params.catalog + " is not a valid catalog within " + params.survey + " and " + params.region;
            }
            if (!ZmastParameterManager.isRaValid(params.ra)) {
                throw params.ra + " is not a valid RA";
            }
            if (!ZmastParameterManager.isDecValid(params.dec)) {
                throw params.dec + " is not a valid Dec";
            }
            if (!ZmastParameterManager.isRadiusValid(params.radius)) {
                throw params.radius + " is not a valid radius";
            }
        }
    }
    static goToCoordsPage(params, newTab) {
        var url = new URL(window.location.origin + Constants.Z_MAST_COORDINATES_PAGE);
        url.searchParams.set(ZmastParameterNames.SURVEY, params.survey);
        url.searchParams.set(ZmastParameterNames.REGION, params.region);
        url.searchParams.set(ZmastParameterNames.CATALOG, params.catalog);
        if (params.searchConditionsApi) {
            url.searchParams.set(ZmastParameterNames.SEARCH_CONDITIONS_API, params.searchConditionsApi);
        }
        if (params.ra) {
            url.searchParams.set(ZmastParameterNames.RA, params.ra);
        }
        if (params.dec) {
            url.searchParams.set(ZmastParameterNames.DEC, params.dec);
        }
        if (params.radius) {
            url.searchParams.set(ZmastParameterNames.RADIUS_API, params.radius);
        }
        if (newTab) {
            window.open(url, "_blank");
        } else {
            window.location.href = url;
        }
    }
    static isSurveyValid(survey) {
        return VALID_SURVEYS.includes(survey);
    }
    static isRaValid(ra) {
        return $.isNumeric(ra);
    }
    static isDecValid(dec) {
        return $.isNumeric(dec);
    }
    static isRadiusValid(radius) {
        return $.isNumeric(radius);
    }
    static isSearchConditionsValid(objid) {
        return true;
    }
    static isObjectIdValid(objid) {
        return !isNaN(objid);
    }
}
module.exports = ZmastParameterManager;
