const ZmastLocalStorage = require("./zmast-local-storage.js");
const ObjectNameLookup = require("./object-name-lookup.js");
const GaWrapper = require("./ga-wrapper.js");
const UserInterfaceCustomizations = require("./user-interface-customizations.js");
const Catalog = require("./catalog.js");
const SANTA_RESOLVER_API = "/api/v0.1/name/lookup?";

CoordinatesModule = window.CoordinatesModule || {};
const coordinatesModule = function() {
    this.area_limit = 10000;
    CoordinatesModule.GOTO_RESULTS = false;
    this.submitParams = {
        ra: null,
        dec: null,
        coords: {
            ra: null,
            dec: null
        },
        target: {
            name: null,
            ra: null,
            dec: null
        },
        y_dimension: null,
        x_dimension: null,
        units: null
    };

    this.messages = {
        resolve_error: "This position did not resolve.",
        ra_dec_missing: "Missing coordinate information."
    };

    this.initElements = function() {
        const tabs = {
            tabLocationCoord: $("#tab-location-coord"),
            tabLocationTarget: $("#tab-location-target")
        };

        const forms = {
            formCutoutCoord: $("#form-cutout-coords"),
            formCutoutTarget: $("#form-cutout-target"),
            formCutoutParams: $("#form-cutout-params")
        };

        const formElements = {
            inputs: {
                inpCoordRa: $("#inp-coord-ra"),
                inpCoordDec: $("#inp-coord-dec"),
                inpTargetName: $("#inp-target-name")
            }
        };

        const buttons = {
            btnResolveCoord: $("#btn-resolve-coord"),
            btnResolveTarget: $("#btn-resolve-target"),
            btnResolutionClose: $("#btn-resolution-close"),
            btnResetForm: $("#btn-reset-form"),
            btnRegionClose: $("button.close")
        };

        const regions = {
            dvContainerTesscut: $("#dv-container-tesscut"),
            altResolution: $("#alert-resolution"),
            spResolutionSuccess: $("#sp-resolution-success"),
            spResolutionCanonical: $("#sp-resolution-canonical"),
            spResolutionError: $("#sp-resolution-error")
        };

        this.elements.tabs = tabs;
        this.elements.forms = forms;
        this.elements.formElements = formElements;
        this.elements.buttons = buttons;
        this.elements.regions = regions;
    };

    this.initTemplateEvents = function() {
        this.elements.buttons.btnResolveCoord.click(this, this.onClickBtnResolve);
        this.elements.buttons.btnResolveTarget.click(this, this.onClickBtnResolve);

        this.elements.forms.formCutoutCoord.find("input").change(this, this.onFormChange);
        this.elements.forms.formCutoutTarget.find("input").change(this, this.onFormChange);

        this.elements.formElements.inputs.inpCoordRa.keyup(this, this.onFormChange);
        this.elements.formElements.inputs.inpCoordDec.keyup(this, this.onFormChange);
        this.elements.formElements.inputs.inpTargetName.keyup(this, this.onFormChange);

        this.elements.formElements.inputs.inpCoordRa.val("");
        this.elements.formElements.inputs.inpCoordDec.val("");
        this.elements.formElements.inputs.inpTargetName.val("");

        const me = this;
        checkForEnterKey = function(evt) {
            if (evt.keyCode == 13) {
                me.onClickBtnResolve(evt);
            }
        };

        this.elements.formElements.inputs.inpCoordRa.keypress(this, checkForEnterKey);
        this.elements.formElements.inputs.inpCoordDec.keypress(this, checkForEnterKey);
        this.elements.formElements.inputs.inpTargetName.keypress(this, checkForEnterKey);

        this.elements.buttons.btnRegionClose.click(this, this.onClickBtnClose);
    };

    this.init = function(params) {
        this.params = params || {};
        this.elements = {};
        this.initElements();
        this.initTemplateEvents();
        this.preventFormSubmit();
        $(this.elements.buttons.btnResolveCoord).prop("disabled", true);
        this.initFormValues();
    };

    this.enableResolveButton = function() {
        if (this.elements.formElements.inputs.inpTargetName.val().trim() === "") {
            $(this.elements.buttons.btnResolveTarget).prop("disabled", true);
        } else {
            $(this.elements.buttons.btnResolveTarget).prop("disabled", false);
        }
    };
    this.preventFormSubmit = function() {
        $("form").submit(function(e) {
            e.preventDefault();
        });
    };

    this.onFormChange = function(e) {
        const scope = e.data;
        scope.closeAllAlerts();
        if (scope.elements.tabs.tabLocationTarget.hasClass("active")) {
            scope.submitParams.target.ra = null;
            scope.submitParams.target.dec = null;
            scope.submitParams.target.name = scope.elements.formElements.inputs.inpTargetName.val();
        } else {
            scope.submitParams.ra = scope.elements.formElements.inputs.inpCoordRa.val();
            scope.submitParams.dec = scope.elements.formElements.inputs.inpCoordDec.val();
            scope.submitParams.coords.ra = null;
            scope.submitParams.coords.dec = null;
        }

        if (scope.elements.formElements.inputs.inpCoordDec.val().trim() == "" || scope.elements.formElements.inputs.inpCoordRa.val().trim() == "") {
            $(scope.elements.buttons.btnResolveCoord).prop("disabled", true);
        } else {
            $(scope.elements.buttons.btnResolveCoord).prop("disabled", false);
        }

        if (scope.elements.formElements.inputs.inpTargetName.val().trim() === "") {
            $(scope.elements.buttons.btnResolveTarget).prop("disabled", true);
        } else {
            $(scope.elements.buttons.btnResolveTarget).prop("disabled", false);
        }
    };

    this.closeAllAlerts = function() {
        this.elements.regions.dvContainerTesscut.find("div.alert.visible, div.alert.collapse.show").each(function() {
            const $alert = $(this);
            if ($alert.hasClass("visible")) {
                $alert.removeClass("visible").addClass("invisible");
            } else if ($alert.hasClass("collapse")) {
                $alert.collapse("hide");
            }
        });
    };

    this.onClickBtnClose = function(e) {
        const region = $(e.target).closest("div.alert");
        if (region.hasClass("visible")) {
            region.removeClass("visible").addClass("invisible");
        } else if (region.hasClass("collapse")) {
            region.collapse("hide");
        }
    };

    this.onClickBtnResolve = function(e) {
        const scope = e.data;
        var resolveParams = {};
        const id = e.target.id;
        if (
            id == scope.elements.buttons.btnResolveCoord.attr("id") ||
            id == scope.elements.formElements.inputs.inpCoordRa.get()[0].id ||
            id == scope.elements.formElements.inputs.inpCoordDec.get()[0].id
        ) {
            resolveParams = {
                ra: scope.elements.formElements.inputs.inpCoordRa.val(),
                dec: scope.elements.formElements.inputs.inpCoordDec.val(),
                success: scope.onResolveSuccess,
                error: scope.onResolveError
            };
            scope.resolvePosition(resolveParams);
        } else {
            resolveParams = {
                target: scope.submitParams.target.name || scope.elements.formElements.inputs.inpTargetName.val(),
                success: scope.onResolveSuccess,
                error: scope.onResolveError
            };
            scope.resolvePosition(resolveParams);
        }
    };

    this.resolvePosition = async function(options) {
        const self = this;
        const ra = options.ra || null;
        const dec = options.dec || null;
        const target = options.target || null;
        var requestOptions = {};
        if (target) {
            this.resolveTarget(target);
        } else {
            requestOptions = {
                url: SANTA_RESOLVER_API,
                crossDomain: true,
                async: false,
                xhrFields: {
                    withCredentials: true
                },
                data: {}
            };
            requestOptions.data.ra = ra;
            requestOptions.data.dec = dec;
            $.ajax(requestOptions)
                .done(function(data, textStatus, jqXHR) {
                    const resolution = data.resolvedCoordinate[0];
                    if (resolution) {
                        self.resolution = resolution;
                        if (options.success) {
                            options.success.call(self, data);
                        }
                    } else {
                        if (options.error) {
                            options.error.call(self);
                        }
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    if (options.error) {
                        options.error.call(self);
                    }
                });
        }
    };
    this.resolveTarget = function(target) {
        const self = this;
        const foundRecord = ObjectNameLookup.getFirstObject(target);
        //console.log(foundRecord);
        if (foundRecord === null) {
            self.onTargetResolveError();
        } else {
            const resolution = {
                ra: foundRecord.ra,
                decl: foundRecord.dec,
                searchString: target,
                canonicalName: foundRecord.IAU_NAME,
                j_name: foundRecord.shortName,
                objid: foundRecord.objID
            };
            self.resolution = resolution;
            const data = { resolvedCoordinate: [] };
            data.resolvedCoordinate.push(resolution);
            self.onResolveSuccess(data);
        }
    };
    this.onResolveSuccess = function(data, textStatus, jqXHR, keepModal) {
        const resolution = data.resolvedCoordinate[0];
        const resolution_text = resolution.canonicalName + "&nbsp&nbsp<strong>RA:</strong> " + resolution.ra + ", <strong>DEC:</strong> " + resolution.decl;
        this.resolvedTarget = resolution;
        this.elements.regions.spResolutionCanonical.html(resolution_text);
        this.elements.regions.spResolutionSuccess.removeClass("hide");
        this.elements.regions.spResolutionError.addClass("hide");
        this.elements.regions.altResolution
            .removeClass("alert-danger")
            .addClass("alert-success")
            .collapse("show");

        if (this.elements.tabs.tabLocationTarget.hasClass("active")) {
            this.submitParams.target.ra = resolution.ra;
            this.submitParams.target.dec = resolution.decl;
        } else {
            this.submitParams.coords.ra = resolution.ra;
            this.submitParams.coords.dec = resolution.decl;
        }
        GaWrapper.writeGaEvent("ZMAST", "Resolver Success", JSON.stringify(this.submitParams));
        if (CoordinatesModule.GOTO_RESULTS) {
            if ($("#search-radius").val()) {
                UserInterfaceCustomizations.saveFormValues();
                const QueryBuilder = require("./query-builder.js");

                let queryBuilder = new QueryBuilder();
                queryBuilder.gotoResults();
            } else {
                $("#provide-radius").show();
            }
            CoordinatesModule.GOTO_RESULTS = false;
        }
    };

    this.onResolveError = function() {
        this.elements.regions.spResolutionError.removeClass("hide");
        $("#alert-resolution").addClass("show");
        $("#resolution-error-message").text("This position did not resolve. Please check input values.");
        this.elements.regions.spResolutionSuccess.addClass("hide");
        this.elements.regions.altResolution.removeClass("alert-success").addClass("alert-danger");
        this.elements.regions.altResolution.collapse("show");
        GaWrapper.writeGaEvent("ZMAST", "Resolver Failure", JSON.stringify(this.submitParams));
    };

    this.onTargetResolveError = function() {
        this.elements.regions.spResolutionError.removeClass("hide");
        $("#resolution-error-message").text("Object not found in " + Catalog.getSurvey().toUpperCase() + " survey.");
        $("#alert-resolution").addClass("show");
        this.elements.regions.spResolutionSuccess.addClass("hide");
        this.elements.regions.altResolution.removeClass("alert-success").addClass("alert-danger");
        this.elements.regions.altResolution.collapse("show");
        GaWrapper.writeGaEvent("ZMAST", "Resolver Failure", JSON.stringify(this.submitParams));
    };

    this.resolve = async function(survey) {
        const scope = this;
        this.resolvedTarget = null;
        var resolveParams = {};
        const target = scope.submitParams.target.name || scope.elements.formElements.inputs.inpTargetName.val();
        if (target) {
            resolveParams = {
                target: target,
                success: scope.onResolveSuccess,
                error: scope.onTargetResolveError
            };
        } else {
            resolveParams = {
                ra: scope.elements.formElements.inputs.inpCoordRa.val(),
                dec: scope.elements.formElements.inputs.inpCoordDec.val(),
                success: scope.onResolveSuccess,
                error: scope.onResolveError
            };
        }
        await scope.resolvePosition(resolveParams);
    };
    this.isResolved = function() {
        if (this.resolvedTarget === null) {
            return false;
        } else {
            return true;
        }
    };
    this.getResolvedTarget = function() {
        return this.resolvedTarget;
    };
    this.initFormValues = function() {
        const targetmode = ZmastLocalStorage.getTargetMode();
        const targetname = ZmastLocalStorage.getTargetName();
        const ra = ZmastLocalStorage.getRA();
        const dec = ZmastLocalStorage.getDec();
        const radius = ZmastLocalStorage.getRadiusForUi();
        const radius_units = ZmastLocalStorage.getRadiusUnits();
        if (targetmode === null || targetmode === "true") {
            $("#tab-location-target").click();
        } else {
            $("#tab-location-coord").click();
        }
        $("#inp-target-name").val(targetname);
        $("#inp-coord-ra").val(ra);
        $("#inp-coord-dec").val(dec);
        if (ra !== "" && ra !== null && dec !== null && dec !== "") {
            //enable validate button
            $("#btn-resolve-coord").prop("disabled", false);
        }
        if (radius) {
            $("#search-radius").val(radius);
            $("#search-radius-units").val(radius_units);
        } else {
            $("#search-radius").val("1");
            $("#search-radius-units").val("arc seconds");
        }
    };
    this.saveFormValues = function() {
        ZmastLocalStorage.setTargetMode($("#tab-location-target").hasClass("active"));
        ZmastLocalStorage.setTargetName($("#inp-target-name").val());
        ZmastLocalStorage.setRA($("#inp-coord-ra").val());
        ZmastLocalStorage.setDec($("#inp-coord-dec").val());
        ZmastLocalStorage.setRadiusForUi($("#search-radius").val());
        ZmastLocalStorage.setRadiusUnits($("#search-radius-units").val());
        if (this.getResolvedTarget() !== null && this.getResolvedTarget() !== undefined && this.getResolvedTarget().objid !== undefined) {
            ZmastLocalStorage.setObjectId(this.getResolvedTarget().objid);
        } else {
            //console.log("obj ID not saved: " + JSON.stringify(this.getResolvedTarget()));
        }
    };
};

coordinatesModule.call(CoordinatesModule);
module.exports = CoordinatesModule;
