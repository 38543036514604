//download many observations
class FitsListDownloader {
    download() {
        // create a mastURL to open a download object in a
        var filterFn = function(row) {
            return !row.target_name || row.target_name.toUpperCase() !== "TESS FFI";
        };
        var obsIds = this.getObsIds(filterFn);
        var obsArray = obsIds.split(",");
        console.log(obsArray);
        var data = "";
        var separator = "";
        for (var i = 0; i < obsArray.length; i++) {
            data = data + separator + "uri=products:caom/observation/" + obsArray[i];
            separator = "&";
        }
        $.ajax({
            type: "POST",
            url: "/api/v0.1/masturl/",
            data: data,
            async: false
        }).done(function(data) {
            //the data returned is a 32-byte Redis key to be used as a URL shortener.
            console.log(data);
            var url = "/api/v0.1/Download/bundle.zip?previews=false&masturl=" + data;
            window.open(url, "iframeTarget");
        });
    }
    getObsIds(filterFn) {
        // obsId column
        return this.getActiveColumns("obsid", filterFn);
    }
    getActiveColumns(index, filterFn) {
        // return CSV of all values in a given column from the filtered results
        var data = $("#results")
            .DataTable()
            .rows({ filter: "applied" })
            .data();
        var returnVals = [];
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                if (filterFn && !filterFn(data[i])) {
                    continue;
                } else {
                    returnVals.push(data[i][index]);
                }
            }
        }
        return returnVals.toString();
    }
}
module.exports = FitsListDownloader;
