const ZmastLocalStorage = require("./zmast-local-storage.js");

class SearchResults {
    getParam(param) {
        const url = new URL(document.location);
        const value = url.searchParams.get(param);
        return value;
    }
    setTargetMessage() {
        //abstract method, do not delete
    }
    renderObjIdLink(data, type, row) {
        //abstract method, do not delete
        return data;
    }
    getApi() {}
    //crossmatch API does not have metadata/info, so we have to get column names from the first object
    getColumnsFromResponseData(data) {
        const columns = [];
        if (data.length > 0) {
            for (var field in data[0]) {
                columns.push({ data: field });
            }
        }
        return columns;
    }
    //summary & detail API does have metadata/info, so we can get column names from the metadata/info
    getColumnsFromResponse(info) {
        const columns = [];
        for (var index = 0; index < info.length; index++) {
            columns.push({ data: info[index].name.replace(".", "\\."), title: info[index].name });
        }
        return columns;
    }
    getColumns(columns) {
        const columns2 = [];
        const columnsArray = JSON.parse(decodeURIComponent(columns));
        for (var index = 0; index < columnsArray.length; index++) {
            columns2.push({ data: columnsArray[index] });
        }
        return columns2;
    }
    static getHeaders(columns) {
        return JSON.parse(decodeURIComponent(columns));
    }
    static setHeaders(table, additionalColumns) {
        var columnHeaders = SearchResults.getHeaders();
        if (additionalColumns) {
            // append column header for button
            const queryColumns = ["Action"];
            columnHeaders = queryColumns.concat(columnHeaders);
        }
        const listHeader = table.columns().header();
        //const listFooter = table.columns().footer();
        const columns = table.settings().init().columns;
        for (var index = 0; index < listHeader.length; index++) {
            if (columns[index].title) {
                listHeader[index].textContent = columns[index].title;
            } else {
                listHeader[index].textContent = columns[index].data;
            }
            //listFooter[index].textContent = columnHeaders[index];
        }
    }
    getLinkColumn(columns) {
        return -1;
    }
    isSupported(survey, region) {
        const SUPPORTED_SURVEYS = ["candels", "goods", "deepspace", "3dhst"];
        return SUPPORTED_SURVEYS.includes(survey);
    }
}
module.exports = SearchResults;
