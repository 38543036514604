class ObjectInfo {
    display(json) {
        const objectInfoData = this.getObjectInfo(json);
        const photometryData = this.getPhotometry(json);
        const morphologyData = this.getMorphology(json);
        this.displayObjectInfo(objectInfoData);
        this.displayPhotometry(photometryData);
        this.displayMorphology(morphologyData);
    }
}
module.exports = ObjectInfo;
