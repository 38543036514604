const Downloader = require("./downloader.js");
const FitsListDownloader = require("./fits-list-downloader.js");
const CaomDownloader = require("./caom-downloader.js");
const SedDownloader = require("./sed-downloader.js");
const Constants = require("./constants.js");

//download a single observation
class FitsDownloader {
    download() {
        const SAMPLE_DOWNLOAD = "/api/v0.1/Download/bundle.zip?previews=false&uri=products:caom/observation/2007813748";
        window.open(SAMPLE_DOWNLOAD, "iframeTarget");
    }
}

class DownloadButtons {
    static init() {
        $("#btn-download").click(function () {
            if (window.location.pathname === Constants.Z_MAST_OBJECT_PAGE) {
                let downloader = new CaomDownloader();
                downloader.download();
            } else {
                let downloader = new Downloader();
                downloader.download();
            }
        });

        $("#btn-download-fits").click(function () {
            if (window.location.pathname === Constants.Z_MAST_OBJECT_PAGE) {
                let downloader = new FitsListDownloader();
                downloader.download();
            }
        });
        $("#sed-download").click(function () {
            let downloader = new SedDownloader();
            downloader.download();
        });
        $("#sed-download-icon").click(function () {
            let downloader = new SedDownloader();
            downloader.download();
        });
    }
    static disable() {
        $("#btn-download").prop("disabled", true);
        $("#sel-filetype").prop("disabled", true);
        $("#btn-download-fits").prop("disabled", true);
    }
}
module.exports = DownloadButtons;
