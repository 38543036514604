const Constants = require("./constants.js");
const Catalog = require("./catalog.js");

class DataDescription {
    static display(hlsp) {
        var specifiedHlsp;
        if (hlsp) {
            specifiedHlsp = hlsp;
        } else {
            specifiedHlsp = DataDescription.getParam("hlsp");
        }

        const map = Catalog.getRegionCatalogMap();
        const surveyList = Object.keys(map);
        var regionList;
        var catalogList;
        var survey;
        var region;
        var catalog;
        var label;
        var description;
        for (var survey of surveyList) {
            if (specifiedHlsp === null || specifiedHlsp === survey) {
                $("#table-of-contents").append("<h3 class='survey-header'>Survey: " + survey + "</h3>");
                regionList = Object.keys(map[survey]);
                for (var region of regionList) {
                    $("#table-of-contents").append("<h4 class='region-header'>Region: " + region + "</h4>");
                    catalogList = Object.keys(map[survey][region]);
                    for (var catalogIndex = 0; catalogIndex < catalogList.length; catalogIndex++) {
                        catalog = map[survey][region][catalogIndex].id;
                        label = map[survey][region][catalogIndex].label;
                        description = map[survey][region][catalogIndex].hoverText;

                        this.displayCatalog(survey, label, catalog, description);
                    }
                }
            }
        }
    }
    static displayCatalog(survey, label, catalog, description) {
        const api = this.getUrl(survey, catalog);
        const self = this;
        $("#table-of-contents").append("<div class='mb-2'><a href=#" + catalog + ">" + label + "</a>&nbsp;<span>" + description + "</span></div>");
        $("#data-descriptions").append(
            "<div class='catalog-header-wrapper' id=" +
                catalog +
                "><h3 class='catalog-header'>" +
                label +
                "</h3><div>" +
                description +
                "</div><div><strong>API name: </strong>" +
                catalog +
                "</div></div>"
        );
        //TODO: success and error are deprecated, use done and fail
        $.ajax({
            url: api,
            method: "get",
            success: function () {},
            error: function (data, textStatus, errorThrown) {
                console.error(textStatus + ": " + errorThrown);
            },
        }).done(function (jsonString) {
            //$("#" + catalog).after('<div><a href="#top">Back to top</a></div>');
            //create heading, table
            self.createTable(catalog, jsonString);
        });
    }
    static getUrl(survey, catalog) {
        return Constants.DATA_URL + survey.toLowerCase() + "/metadata/" + catalog + ".json";
    }
    static createTable(catalog, data) {
        //create table
        const table = $("<table class='metadata' id='" + catalog + "-table'></table>");
        $("#" + catalog).after(table);
        //create table header
        const header = $("<thead class='table_header'><tr></tr></thead>");
        var col;
        const data_keys = ["column_name", "description", "datatype", "unit"];
        $.each(data_keys, function (ind_header, val_header) {
            col = $("<th class='" + val_header + "'>" + val_header + "</th>");
            header.append(col);
        });
        table.append(header);

        //create table body
        const tableBody = document.createElement("tbody");
        var odd_even = false;
        $.each(Object.keys(data), function (i, col) {
            const columnData = data[col];
            const tableRow = tableBody.insertRow();
            tableRow.className = odd_even ? "odd" : "even";
            $.each(data_keys, function (j, key) {
                const cell = tableRow.insertCell();
                const value = columnData[key];
                const valueStr = value ? value.toString() : "null";
                cell.appendChild(document.createTextNode(valueStr));
            });
            odd_even = !odd_even;
        });
        table.append(tableBody);
    }
    //get URL parameter
    static getParam(paramName) {
        const url = new URL(window.location.href);
        const param = url.searchParams.get(paramName);
        if (param) {
            return param.toUpperCase();
        } else {
            return null;
        }
    }
}

$(document).ready(function () {
    if (Constants.Z_MAST_DATA_PAGE === window.location.pathname) {
        DataDescription.display();
    }
    if (Constants.Z_MAST_HLSP_PAGES.includes(window.location.pathname)) {
        for (var record of Constants.Z_MAST_HLSP_MAP) {
            if (record.page === window.location.pathname) {
                DataDescription.display(record.hlsp);
            }
        }
    }
});
module.exports = DataDescription;
