class ImagesWaitMessage {
    static show(text) {
        $("#images-row").css("height", "200px");
        $("#images-row").loadingModal({
            text: text,
            color: "#00617E",
            backgroundColor: "lightGray",
            animation: "wave"
        });
    }
    static destroy() {
        $("#images-row").loadingModal("destroy");
        $("#images-row").css("height", "auto");
        $("#images-row").empty();
    }
}

module.exports = ImagesWaitMessage;
