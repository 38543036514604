const RadioSelector = require("./radio-selector.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const PowerTips = require("./powertips.js");

const DEFAULT_CATALOG = { CANDELS: "candels_master_view", GOODS: "goods_master_view", DEEPSPACE: "deepspace_summary", "3DHST": "3dhst_summary" };
const CANDELS_ONLY_REGIONS = ["EGS", "UDS", "COSMOS"];

const REGION_CATALOG_MAP = {
    "3DHST": {
        ALL: [
            { id: "hlsp_3dhst_summary", label: "Summary", hoverText: "Subset of fields for detail view" },
            { id: "hlsp_3dhst_photometry", label: "Multiband Photometry", hoverText: "Photometry information for SED creation" },
            { id: "hlsp_3dhst_mosaiccat", label: "4.1 Catalog", hoverText: "Image mosaic sources including photometry and derived parameters" },
            { id: "hlsp_3dhst_catalogrecord", label: "Full", hoverText: "Full set of catalog information" },
        ],
    },
    DEEPSPACE: {
        ALL: [
            { id: "deepspace_summary", label: "Summary", hoverText: "Subset of fields for detail view." },
            { id: "deepspace_photometry", label: "Multiband Photometry", hoverText: "Photometry information for SED creation." },
            { id: "catalogrecord", label: "Full", hoverText: "Full set of catalog information" },
        ],
    },
    CANDELS: {
        ALL: [
            { id: "candels_master_view", label: "Summary", hoverText: "Subset of fields for detail view." },
            { id: "candels_photometry_view", label: "Multiband Photometry", hoverText: "Photometry information for SED creation." },
            { id: "candels_sfr_view", label: "Star Formation Rates", hoverText: "Union of individual field SFR tables." },
        ],
        EGS: [
            {
                id: "egs_tot_multiband_f160w_v1",
                label: "Multiband Photometry",
                hoverText: "Join of photometry tables for EGS.",
                linkLabel: "Galametz et al. 2013",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/aa66cb",
            },
            {
                id: "egs_v1_mass",
                label: "Stellar Mass",
                hoverText: "Redshift, flags, stellar masses; calculated with different models.",
                linkLabel: "Santini, P. et al. 2015",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/aa66cb",
            },
            {
                id: "egs_v1_physpar",
                label: "Physical Parameters",
                hoverText: "Physical parameters; calculated with different models.",
                linkLabel: "Santini, P. et al. 2015",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/aa66cb",
            },
            {
                id: "egs_v1_photoz",
                label: "Photometric Redshifts",
                hoverText: "Photometric redshifts.",
                linkLabel: "Barro et al, 2019",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/aa66cb",
            },
            {
                id: "egs_barro19_multi_v1_sfr_egs",
                label: "Star Formation Rates",
                hoverText: "Star formation rates, as well as mid-to-far IR fluxes, dust attenuations. ",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
        ],
        UDS: [
            {
                id: "uds_tot_multiband_f160w_v1",
                label: "Multiband Photometry",
                hoverText: "Join of 4 photometry tables for UDS.",
                linkLabel: "Galametz et al, 2013",
                url: "https://iopscience.iop.org/article/10.1088/0067-0049/206/2/10",
            },
            {
                id: "uds_santini_v1_mass",
                label: "Stellar Mass",
                hoverText: "Redshift, flags, stellar masses; calculated with different models.",
                linkLabel: "Santini, P. et al, 2015",
                url: "https://ui.adsabs.harvard.edu/abs/2015ApJ...801...97S/abstract",
            },
            {
                id: "uds_santini_v1_physpar",
                label: "Physical Parameters",
                hoverText: "Physical parameters; calculated with different models.",
                linkLabel: "Santini, P. et al, 2015",
                url: "https://ui.adsabs.harvard.edu/abs/2015ApJ...801...97S/abstract",
            },
            {
                id: "uds_barro19_multi_v1_sfr_uds",
                label: "Star Formation Rates",
                hoverText: "Star formation rates, as well as mid-to-far IR fluxes, dust attenuations.",
                linkLabel: "Barro, Guillermo et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B",
            },
        ],
        COSMOS: [
            {
                id: "cos_tot_multiband_f160w_v1",
                label: "Multiband Photometry",
                hoverText: "Join of photometry tables for COSMOS.",
                linkLabel: "Nayyeri et al, 2017",
                url: "https://iopscience.iop.org/article/10.3847",
            },
            {
                id: "cos_v1_mass",
                label: "Stellar Mass",
                hoverText: "Redshift, flags, stellar masses; calculated with different models.",
                linkLabel: "Nayyeri et al, 2017",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/228/1/7",
            },
            {
                id: "cos_v1_physpar",
                label: "Physical Parameters",
                hoverText: "Physical parameters; calculated with different models.",
                linkLabel: "Nayyeri et al, 2017",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/228/1/7",
            },
            {
                id: "cos_v1_photoz",
                label: "Photometric Redshifts",
                hoverText: "Photometric redshifts.",
                linkLabel: "Nayyeri et al, 2017",
                url: "https://iopscience.iop.org/article/10.3847/1538-4365/228/1/7",
            },
            {
                id: "cos_barro19_multi_v1_sfr_cos",
                label: "Star Formation Rates",
                hoverText: "Star formation rates, as well as mid-to-far IR fluxes, dust attenuations.",
                linkLabel: "Barro at el, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
        ],
        GOODS_S: [
            {
                id: "goodss_tot_multiband_f160w_v1",
                label: "Multiband Photometry",
                hoverText: "Join of 4 photometry tables for GOODS-S.",
                linkLabel: "Guo, et al, 2013",
                url: "https://iopscience.iop.org/article/10.1088/0067-0049/207/2/24",
            },
            {
                id: "goodss_santini_v1_mass",
                label: "Stellar Mass",
                hoverText: "Redshift, flags, stellar masses; calculated with 9 different models.",
                linkLabel: "Santini, P. et al, 2015",
                url: "https://ui.adsabs.harvard.edu/abs/2015ApJ...801...97S/abstract",
            },
            {
                id: "goodss_santini_v1_physpar",
                label: "Physical Parameters",
                hoverText: "Physical parameters; calculated with 9 different models.",
                linkLabel: "Santini, P. et al, 2015",
                url: "https://ui.adsabs.harvard.edu/abs/2015ApJ...801...97S/abstract",
            },
            {
                id: "goodss_barro19_multi_v1_sfr_gds",
                label: "Star Formation Rates",
                hoverText: "Star formation rates, as well as mid-to-far IR fluxes, dust attenuations.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
        ],
        GOODS_N: [
            {
                id: "goodsn_multiband_view",
                label: "Multiband Photometry",
                hoverText: "Join of 3 photometry tables for GOODS-N.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_photometry_shards",
                label: "SHARDS Photometry",
                hoverText: "Photometry from SHARDS survey.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_redshift",
                label: "Redshifts",
                hoverText: "Redshifts.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_supplemetary_photoz",
                label: "Photometric Redshifts",
                hoverText: "Supplementary photometric redshifts from different investigators.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_mass_fast",
                label: "Stellar Mass (FAST)",
                hoverText: "Stellar population properties using the FAST code.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_mass_synthesizer",
                label: "Stellar Mass (Synthesizer)",
                hoverText: "Stellar population properties using the Synthesizer code.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_emission_line_grism_g141",
                label: "Emission Line Fluxes (G141)",
                hoverText: "Emission line fluxes from grism data.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_emission_line_grism_g102",
                label: "Emission Line Fluxes (G102)",
                hoverText: "Emission line fluxes from grism data.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
            {
                id: "goodsn_barro19_multi_v1_sfr_gdn",
                label: "Star Formation Rates",
                hoverText: "Star formation rates, as well as mid-to-far IR fluxes, dust attenuations.",
                linkLabel: "Barro et al, 2019",
                url: "https://ui.adsabs.harvard.edu/abs/2019ApJS..243...22B/abstract",
            },
        ],
    },
    GOODS: {
        ALL: [
            {
                id: "goods_master_view",
                label: "Summary",
                hoverText: "Subset of fields for detail view.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "goods_photometry_view",
                label: "Multiband Photometry",
                hoverText: "Photometry information for SED creation.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
        ],
        GOODS_N: [
            {
                id: "h_goods_nz_r2_0z",
                label: "F850LP Photometry",
                hoverText: "SExtractor results for F850LP.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "h_goods_nb_r2_0z",
                label: "F435W Photometry",
                hoverText: "SExtractor results for F435W.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "h_goods_nv_r2_0z",
                label: "F606W Photometry",
                hoverText: "SExtractor results for F606W.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "h_goods_ni_r2_0z",
                label: "F775W Photometry",
                hoverText: "SExtractor results for F775W.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
        ],
        GOODS_S: [
            {
                id: "h_goods_sz_r2_0z",
                label: "F850LP Photometry",
                hoverText: "SExtractor results for F850LP.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "h_goods_sb_r2_0z",
                label: "F435W Photometry",
                hoverText: "SExtractor results for F435W.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "h_goods_sv_r2_0z",
                label: "F606W Photometry",
                hoverText: "SExtractor results for F606W.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
            {
                id: "h_goods_si_r2_0z",
                label: "F775W Photometry",
                hoverText: "SExtractor results for F775W.",
                linkLabel: "Giavalisco, M, 2008",
                url: " https://ui.adsabs.harvard.edu/abs/2012yCat.2318....0G/abstract",
            },
        ],
    },
};

class Catalog {
    static init() {
        $("#catalog").on("click", function () {
            const SearchConditions = require("./search-conditions.js");
            SearchConditions.load();
        });
        Catalog.display();
    }
    static display() {
        //remove obsolete buttons
        $("#catalog").empty();
        const catalogList = Catalog.getCatalogList();
        const length = catalogList.length;
        var index;
        var element;
        const buttonTemplate = $('<label for="clone">Clone</label>');
        var inputElement;
        var elementId;
        const verticalBar = '<span class="col-form-label blue">|</span>';
        for (index = 0; index < length; index++) {
            element = buttonTemplate.clone();
            if (index === 0) {
                element.addClass("active");
            }
            elementId = catalogList[index].id;
            element.text(catalogList[index].label);
            element.attr("for", elementId);
            element.data("powertip", Catalog.getPowertipElement(catalogList[index]));
            inputElement = $('<input type="radio" name="catalog"/>');
            inputElement.attr("id", elementId);
            inputElement.attr("value", elementId);
            element.append(inputElement);
            element.appendTo("#catalog");
            if (index < length - 1) {
                element.after(verticalBar);
            }
        }
        RadioSelector.activateAll();
        const catalog = ZmastLocalStorage.getCatalog();
        if (catalog !== null) {
            $("input[name=catalog][value=" + catalog + "]").click();
        }
        const SearchConditions = require("./search-conditions.js");
        SearchConditions.load();
        PowerTips.setAll();
    }
    static getPowertipElement(catalog) {
        var powertipElement;
        if (catalog.hoverText) {
            if (catalog.linkLabel) {
                if (catalog.url) {
                    powertipElement = sprintf("%s <a target='_blank' href='%s'>%s</a>", catalog.hoverText, catalog.url, catalog.linkLabel);
                } else {
                    powertipElement = sprintf("%s %s", catalog.hoverText, catalog.linkLabel);
                }
            } else {
                powertipElement = catalog.hoverText;
            }
            return powertipElement;
        } else return "";
    }
    static getCatalog() {
        if ($("#catalog .active input").length > 0) {
            return $("#catalog .active input")[0].value;
        } else {
            const survey = Catalog.getSurvey();
            if (survey === "candels") {
                return DEFAULT_CATALOG.CANDELS;
            }
            if (survey === "goods") {
                return DEFAULT_CATALOG.GOODS;
            }
            if (survey === "deepspace") {
                return DEFAULT_CATALOG.DEEPSPACE;
            }
            if (survey === "3dhst") {
                return DEFAULT_CATALOG["3DHST"];
            }
            return DEFAULT_CATALOG.CANDELS;
        }
    }
    static getCatalogList() {
        var region = Catalog.getRegion().toUpperCase();
        if (region === "GOODS-NORTH") {
            region = "GOODS_N";
        }
        if (region === "GOODS-SOUTH") {
            region = "GOODS_S";
        }
        const survey = Catalog.getSurvey();
        if (survey === "candels") {
            return REGION_CATALOG_MAP.CANDELS[region];
        }
        if (survey === "goods") {
            if (CANDELS_ONLY_REGIONS.includes(region)) {
                region = "ALL";
            }
            return REGION_CATALOG_MAP.GOODS[region];
        }
        if (survey === "deepspace") {
            return REGION_CATALOG_MAP.DEEPSPACE[region];
        }
        if (survey === "3dhst") {
            return REGION_CATALOG_MAP["3DHST"][region];
        }
        return REGION_CATALOG_MAP.CANDELS[region];
    }
    static getSurvey() {
        return $("#survey .active input")[0].value;
    }
    static getRegion() {
        return $("#region .active input")[0].value;
    }
    static getRegionCatalogMap() {
        return REGION_CATALOG_MAP;
    }
    static isRegionValid(params) {
        const survey = params.survey.toUpperCase();
        var region = params.region.toUpperCase();
        if (region === "GOODS-NORTH") {
            region = "GOODS_N";
        }
        if (region === "GOODS-SOUTH") {
            region = "GOODS_S";
        }
        return region in REGION_CATALOG_MAP[survey];
    }
    static isCatalogValid(params) {
        const survey = params.survey.toUpperCase();
        var region = params.region.toUpperCase();
        if (region === "GOODS-NORTH") {
            region = "GOODS_N";
        }
        if (region === "GOODS-SOUTH") {
            region = "GOODS_S";
        }
        var ret = false;
        for (var index = 0; index < REGION_CATALOG_MAP[survey][region].length; index++) {
            if (REGION_CATALOG_MAP[survey][region][index].id.toUpperCase() === params.catalog) {
                ret = true;
            }
        }
        return ret;
    }
}
module.exports = Catalog;
