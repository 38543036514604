const HOVER_TEXT_FOR_SURVEYS = {
    CANDELS: "<a target='_blank' href='https://archive.stsci.edu/prepds/candels/'>CANDELS</a> has near infrared extragalactic survey using HST WIFC3/IR & ACS.",
    GOODS: "<a target='_blank' href='https://archive.stsci.edu/prepds/goods/'>GOODS</a> has deep extragalactic observations using HST/ACS.",
    DEEPSPACE:
        "<a target='_blank' href='https://archive.stsci.edu/prepds/frontier/'>HFF-DEEPSPACE</a> has extragalactic UV to NIR data for the Hubble Frontier Fields and associated parallels.",
    "3D-HST":
        "<a target='_blank' href='https://archive.stsci.edu/prepds/3d-hst/'>3D-HST</a> is a near-infrared spectroscopic survey with the Hubble Space Telescope designed to study the physical processes that shape galaxies in the distant Universe.",
};

const HOVER_TEXT_FOR_REGIONS = {
    ALL: "All Regions",
    COSMOS: "The Cosmic Evolution Survey",
    EGS: "Extended Groth Strip",
    UDS: "Ultra Deep Survey",
    GOODS_NORTH: "The Great Observatories Origins Deep Survey - NORTH",
    GOODS_SOUTH: "The Great Observatories Origins Deep Survey - SOUTH",
};

const HOVER_TEXT_FOR_SED = {
    SETTINGS: "SED Settings",
    EXPORT: "Export SED data",
};

class PowerTips {
    static init() {
        //surveys
        $("#candels-label").data("powertip", HOVER_TEXT_FOR_SURVEYS.CANDELS);
        $("#goods-label").data("powertip", HOVER_TEXT_FOR_SURVEYS.GOODS);
        $("#deepspace-label").data("powertip", HOVER_TEXT_FOR_SURVEYS.DEEPSPACE);
        $("#3d-hst-label").data("powertip", HOVER_TEXT_FOR_SURVEYS["3D-HST"]);
        //regions
        $("#all-label").data("powertip", HOVER_TEXT_FOR_REGIONS.ALL);
        $("#cosmos-label").data("powertip", HOVER_TEXT_FOR_REGIONS.COSMOS);
        $("#goods-north-label").data("powertip", HOVER_TEXT_FOR_REGIONS.GOODS_NORTH);
        $("#goods-south-label").data("powertip", HOVER_TEXT_FOR_REGIONS.GOODS_SOUTH);
        $("#egs-label").data("powertip", HOVER_TEXT_FOR_REGIONS.EGS);
        $("#uds-label").data("powertip", HOVER_TEXT_FOR_REGIONS.UDS);

        //Powertips for catalogs are set in Catalog.display()
        PowerTips.setAll();
    }
    static initSedTooltips() {
        $("#sed-settings-gear-icon").data("powertip", HOVER_TEXT_FOR_SED.SETTINGS);
        $("#sed-download-icon").data("powertip", HOVER_TEXT_FOR_SED.EXPORT);
        PowerTips.setSed();
    }
    static setAll() {
        $(".radio-group label").powerTip({
            placement: "s",
            smartPlacement: true,
            mouseOnToPopup: true,
        });
    }
    static setSed() {
        //popupClass allows us to style SED powertips differently from the other powertips
        $(".sed-icons").powerTip({
            placement: "s",
            smartPlacement: true,
            popupClass: "sedIcon",
        });
    }
}

module.exports = PowerTips;
