const ObjectInfoFactory = require("./object-info-factory.js");
const Constants = require("./constants.js");
const ZmastLocalStorage = require("./zmast-local-storage.js");
const ErrorMessage = require("./error-message.js");

class ObjectInfoData {
    getObject() {
        const api = this.getApi();
        const postData = this.getPostData();
        if (postData !== null) {
            //TODO: success and error are deprecated, use done and fail
            $.ajax({
                url: api,
                method: "POST",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(postData),
                error: function (data, textStatus, errorThrown) {
                    console.error(textStatus + ": " + errorThrown);
                },
            }).done(function (json) {
                if (json.data.length === 0) {
                    console.log("No data found");
                    ErrorMessage.setMessage("No data found");
                    $("#object-info-goods").show();
                    $("#object-info-candels").hide();
                    $("#object-info-deepspace").hide();
                }
                if (json.data.length > 0) {
                    let factory = new ObjectInfoFactory();
                    let objectInfo = factory.getClass();
                    objectInfo.display(json);
                }
            });
        }
    }
    getApi() {
        const survey = ZmastLocalStorage.getSurvey();
        return Constants.DATA_URL + survey;
    }
    getPostData() {
        const SURVEYS = { candels: "candels_master_view", goods: "goods_master_view", deepspace: "deepspace_summary", "3dhst": "hlsp_3dhst_summary" };
        const survey = ZmastLocalStorage.getSurvey();
        var catalog = SURVEYS[survey];
        if (catalog == undefined) {
            catalog = ZmastLocalStorage.getCatalog();
        }
        const objId = ZmastLocalStorage.getObjectId();
        if (objId == undefined || objId == null) {
            return null;
        } else {
            const postData = {
                catalog: catalog,
                objid: objId,
                flatten_response: false,
                raw: false,
            };
            return postData;
        }
    }
}
module.exports = ObjectInfoData;
