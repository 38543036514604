const FilenameParser = require("./filename-parser.js");
const FitsImages = require("./fits-images.js");
const ImagesWaitMessage = require("./images-wait-message.js");
const Constants = require("./constants.js");
const CutoutImageSorter = require("./cutout-image-sorter.js");

class ZipReader {
    static toggleImageInfoPopup() {
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
    }
    //get every file from the astrocut ZIP file then call ZipReader.show() to display images in the web page
    static getFileList(file, survey) {
        ImagesWaitMessage.show("Loading images...");
        const fileList = [];
        ZipReader.figureList = [];
        ZipReader.survey = survey.toUpperCase();
        $("#astrocut-image-candels").addClass("hide");
        $("#astrocut-image-goods").addClass("hide");
        $("#astrocut-image-deepspace").addClass("hide");
        $("#astrocut-image-3dhst").addClass("hide");
        JSZipUtils.getBinaryContent(file, function (err, data) {
            if (err) {
                ImagesWaitMessage.destroy();
                $("#images-info").text("Unable to retrieve cutout images");
                throw err; // or handle err
            }
            if (ZipReader.isDataFound(data)) {
                JSZip.loadAsync(data).then(function (zip) {
                    ZipReader.imageCount = 0;
                    Object.keys(zip.files).forEach(function (filename) {
                        //console.log(filename);
                        if (filename && !filename.startsWith("__MACOSX") && !filename.endsWith("/")) {
                            var selected = ZipReader.isSelected(filename);
                            fileList.push({ selected: selected, name: filename });
                        }
                        const type = ZipReader.getType(filename);
                        zip.files[filename].async(type).then(function (fileData) {
                            if (filename.endsWith(".txt")) {
                                //console.log(fileData);
                            } else {
                                if (filename.endsWith(".jpg")) {
                                    ZipReader.show(filename, fileData, "image/jpeg");
                                } else {
                                    if (filename.endsWith(".png")) {
                                        ZipReader.show(filename, fileData, "image/png");
                                    } else {
                                        if (filename.endsWith(".fits")) {
                                            const type = {
                                                type: "image/fits",
                                                lastModified: Date.now(),
                                            };
                                            const blob = new Blob([fileData], type);
                                            FitsImages.show(blob, { scale: "linear", colormap: "sls" });
                                        }
                                    }
                                }
                            }
                        });
                    });
                    if (fileList.length === 0) {
                        $("#images-row").html(Constants.NO_IMAGES_MESSAGE);
                        $("#images-info").hide();
                    }
                }),
                    function (e) {
                        console.error("Error reading " + data.name + ": " + e.message);
                    };
            } else {
                $("#images-row").html(Constants.NO_IMAGES_MESSAGE);
                $("#images-info").hide();
            }
        });
    }
    static show(file, data, typeStr) {
        const url = window.URL || window.webkitURL;
        const type = {
            type: typeStr,
            lastModified: Date.now(),
        };
        const blob = new Blob([data], type);
        const imageUrl = url.createObjectURL(blob);
        const fileProps = FilenameParser.parse(file);
        if (ZipReader.imageCount === 0) {
            ImagesWaitMessage.destroy();
        }
        $("#images-row").append(ZipReader.getFigureHtml(fileProps, imageUrl));
        //show on left side
        if (ZipReader.isSpecial(fileProps.filter)) {
            if (ZipReader.survey === "CANDELS") {
                $("#astrocut-image-candels").removeClass("hide");
                $("#astrocut-image-candels").prop("src", imageUrl);
            }
            if (ZipReader.survey === "GOODS") {
                $("#astrocut-image-goods").removeClass("hide");
                $("#astrocut-image-goods").prop("src", imageUrl);
            }
            if (ZipReader.survey === "DEEPSPACE") {
                $("#astrocut-image-deepspace").removeClass("hide");
                $("#astrocut-image-deepspace").prop("src", imageUrl);
            }
            if (ZipReader.survey === "3DHST") {
                $("#astrocut-image-3dhst").removeClass("hide");
                $("#astrocut-image-3dhst").prop("src", imageUrl);
            }
        }
        ZipReader.figureList.push(fileProps.filter);
        CutoutImageSorter.sortFiguresByOrder();
    }
    static getFigureHtml(fileProps, imageUrl) {
        const id = "astrocut-image-" + ZipReader.imageCount++;
        const order = CutoutImageSorter.getSortPosition(fileProps);

        // create a popup for image info which will be inserted in the figure caption below
        const imageInfoPopupHtml = sprintf(
            "<span class='popup' id='%s-info' onclick='document.getElementById(\"%s-info-popup\").classList.toggle(\"show\")'>" +
                "Info<span class='popuptext' id='%s-info-popup'>Project: %s<br/>Observatory: %s<br/>Instrument: %s<br/>Target: %s" +
                "<br/>Filter: %s<br/>Version: %s<br/>Product Type: %s</span></span>",
            id,
            id,
            id,
            fileProps.project,
            fileProps.observatory,
            fileProps.instrument,
            fileProps.target,
            fileProps.filter,
            fileProps.version,
            fileProps.productType
        );
        // create a figure caption which will be inserted in the figure below
        const figureCaptionHtml = sprintf(
            "<figcaption class='image-caption'> <span class='fig-caption-heading'>%s - %s</span> <span class='value'>%s | %s | %s</span></figcaption>",
            fileProps.project,
            fileProps.observatory,
            fileProps.instrument,
            fileProps.filter,
            imageInfoPopupHtml
        );
        // make up the HTML for the figure with the unique id and data-order so that it can be sorted by sortFiguresByOrder()
        const figureHtml = sprintf("<figure id='%s' data-order='%d'>%s<img class='z-image' src='%s'> </figure>", id, order, figureCaptionHtml, imageUrl);
        return figureHtml;
    }

    //If isSpecial() === true, the current image should be displayed in the left panel
    static isSpecial(filter) {
        var val = false;
        switch (ZipReader.survey) {
            case "CANDELS":
            case "DEEPSPACE":
            case "3DHST":
                if (filter === "F160W") {
                    val = true;
                }
                break;
            case "GOODS":
                if (filter === "F850LP") {
                    val = true;
                }
                break;
        }
        return val;
    }
    // returns a URL you can use as a href
    static makeTextFile(text) {
        const data = new Blob([text], { type: "text/plain" });
        const textFile = (textFile = window.URL.createObjectURL(data));
        return textFile;
    }
    static isDataFound(data) {
        if ("TextDecoder" in window) {
            const textDecoder = new TextDecoder("utf-8");
            if (textDecoder.decode(data) === '{"results": [], "msg": "No data at cutout size/location. Cutout not performed."}') {
                return false;
            } else {
                return true;
            }
        } else {
            console.log("The browser does not support TextDecoder");
            return true;
        }
    }
    static isSelected(name) {
        var ret = false;
        const fileProps = FilenameParser.parse(name);
        if (fileProps.resolution.endsWith("30MAS")) {
            //return false
        } else {
            if (ZipReader.survey === "CANDELS" && name.startsWith("hlsp_candels")) {
                ret = true;
            }
            if (ZipReader.survey === "GOODS" && name.startsWith("hlsp_goods")) {
                ret = true;
            }
            if (ZipReader.survey === "DEEPSPACE") {
                ret = true;
            }
            if (ZipReader.survey === "3DHST") {
                ret = true;
            }
        }
        return ret;
    }
    static getType(filename) {
        var type;
        if (filename.endsWith(".txt")) {
            type = "string";
        } else {
            type = "uint8array"; // choices are: string, array, uint8array, arraybuffer.
        }
        return type;
    }
}

module.exports = ZipReader;
