const TabsOfSearchTypes = require("./tabs-of-search-types.js");
const SearchButton = require("./search-button.js");
const CoordinatesModule = require("./coordinates-module.js");
const RadioSelector = require("./radio-selector.js");
const Survey = require("./survey.js");
const Region = require("./region.js");
const Catalog = require("./catalog.js");
const SearchConditions = require("./search-conditions.js");
const UserInterfaceCustomizations = require("./user-interface-customizations.js");
const PowerTips = require("./powertips.js");
const ImageTools = require("./image-tools.js");

const Z_MAST_SEARCH_PAGE = ["/", "/index.html"];

$(document).ready(function () {
    if (Z_MAST_SEARCH_PAGE.includes(window.location.pathname)) {
        TabsOfSearchTypes.init();
        SearchButton.init();
        CoordinatesModule.init({
            ra: null,
            dec: null,
            planet: null,
        });
        SearchConditions.init();
        RadioSelector.init();
        Survey.init();
        Region.init();
        Catalog.init();
        const userInterface = new UserInterfaceCustomizations();
        userInterface.init();
        PowerTips.init();
    }
    //some documentation pages use ImageTools
    ImageTools.initExpander();
});
