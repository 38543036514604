// file naming convention: https://outerspace.stsci.edu/display/MASTDOCS/File+Naming+Convention
class FilenameParser {
    static parse(file) {
        const filename = FilenameParser.getFileName(file).toUpperCase();
        const partsList = filename.split("_");
        const imageInfo = {};
        if (partsList.length >= 8) {
            imageInfo.hlsp = partsList[0];
            imageInfo.project = partsList[1];
            imageInfo.observatory = partsList[2];
            imageInfo.instrument = partsList[3];
            imageInfo.target = partsList[4];
            imageInfo.filter = partsList[5];
            imageInfo.version = partsList[6];
            imageInfo.productType = partsList[7];
            if (imageInfo.target.includes("MAS")) {
                const parts = imageInfo.target.split("-");
                if (parts.length > 2) {
                    imageInfo.resolution = parts[2];
                } else {
                    imageInfo.resolution = "Not available";
                }
            } else {
                imageInfo.resolution = "Not available";
            }
        }
        return imageInfo;
    }
    //remove folder and extension
    static getFileName(fullpath) {
        const pattern = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpeg|.jpg|.png)$/;
        try {
            const match = pattern.exec(fullpath);
            if (match) {
                const filename = match[0];
                return filename;
            } else {
                //throw "no match found";
                return "";
            }
        } catch (exception) {
            throw "Parse error: " + exception;
        }
    }
}

module.exports = FilenameParser;
