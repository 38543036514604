const LS_KEY = {
    SED_FILE_TYPE: "zmast.sed-file-type",
    SED_X_SCALING: "zmast.sed-x-scaling",
    SED_X_UNITS: "zmast.sed-x-units",
    SED_Y_SCALING: "zmast.sed-y-scaling",
    SED_Y_UNITS: "zmast.sed-y-units",
};
const DEFAULT_FILE_TYPE = "csv";

class SedSettingsStorage {
    static getSedFileType() {
        var type = localStorage.getItem(LS_KEY.SED_FILE_TYPE);
        if (type == null){
            type = DEFAULT_FILE_TYPE;
        }
        return type;
    }
    static setSedFileType(value) {
        localStorage.setItem(LS_KEY.SED_FILE_TYPE, value);
    }
    static getSedXScaling() {
        return localStorage.getItem(LS_KEY.SED_X_SCALING);
    }
    static setSedXScaling(value) {
        localStorage.setItem(LS_KEY.SED_X_SCALING, value);
    }
    static getSedYScaling() {
        return localStorage.getItem(LS_KEY.SED_Y_SCALING);
    }
    static setSedYScaling(value) {
        localStorage.setItem(LS_KEY.SED_Y_SCALING, value);
    }
    static getSedXUnits() {
        return localStorage.getItem(LS_KEY.SED_X_UNITS);
    }
    static setSedXUnits(value) {
        localStorage.setItem(LS_KEY.SED_X_UNITS, value);
    }
    static getSedYUnits() {
        return localStorage.getItem(LS_KEY.SED_Y_UNITS);
    }
    static setSedYUnits(value) {
        localStorage.setItem(LS_KEY.SED_Y_UNITS, value);
    }
}
module.exports = SedSettingsStorage;
