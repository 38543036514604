class TableFilters {
    static init(table, excludeColumnList) {
        TableFilters.pendingList = {};
        TableFilters.executedList = {};
        const tableSettings = table.table().init();
        const columnsSettings = tableSettings.columns;
        // Append the search icon to each header
        table
            .table()
            .columns()
            .every(function() {
                const header = $(this.header());
                const colIndex = this.index();
                const colTitle = header.text();
                const colSettings = columnsSettings[colIndex];
                const colName = colSettings.name;
                if (excludeColumnList != null && excludeColumnList.includes(colSettings.title)) {
                    header.css({ cssText: "padding: 10px 20px !important" });
                    header.removeClass("sorting_asc");
                    header.removeClass("sorting_desc");
                    header.removeAttr("aria-label");
                    header.removeAttr("aria-sort");
                } else {
                    const btnHtml =
                        '<button id="btnColumnSearch_' +
                        colName +
                        '" class="ui-button ui-widget ui-corner-all column-search powertip-toggle ui-button-icon-only" name="' +
                        colName +
                        '" title="Search ' +
                        colTitle +
                        '"><span class="column-search unsearched"> </span></button>';
                    const titleHtml = '<div id="title_' + colName + '" class="column-title" name="' + colName + '">' + colTitle + "</div> ";

                    header.html(btnHtml + titleHtml);
                    header.find(".column-search.powertip-toggle").data("headerName", colName);
                }
            });

        // Append powertip
        $(".column-search.powertip-toggle").powerTip({
            manual: true,
            mouseOnToPopup: true,
            smartPlacement: true,
            placement: "s"
        });

        $(".column-search.powertip-toggle").on({
            powerTipOpen: function() {
                const powerTipElm = $("#powerTip");
                const searchButton = powerTipElm.find("button.column-search.powertip-search");
                const input = powerTipElm.find("input");
                powerTipElm.on("click", function(e) {
                    e.stopPropagation();
                });

                searchButton.one("click", function() {
                    TableFilters.handleTableColumnSearch(table, input);
                });

                input.on("keyup", function(e) {
                    e.preventDefault();
                    if (e.keyCode === 13 || e.key === "Enter") {
                        TableFilters.handleTableColumnSearch(table, input);
                    }
                });

                $(document).on("keyup", TableFilters.handleSearchTooltipExit);
                $(document).on("click", TableFilters.handleSearchTooltipExit);
            },
            powerTipClose: function() {
                const input = $("#powerTip input");
                const inputVal = input.val();
                const inputName = input.attr("name");
                TableFilters.setPendingStatus(inputName, inputVal);
                $(document).off("keyup", TableFilters.handleSearchTooltipExit);
                $(document).off("click", TableFilters.handleSearchTooltipExit);
            }
        });
        TableFilters.handleSearchToggle();
    }

    static searchTable(value, column) {
        if (column) {
            column.search(value).draw();
        }
    }

    static handleSearchToggle() {
        // Click event to toggle powertip and insert search input field
        $("button.column-search.powertip-toggle").on("click", function(e) {
            const elemJQ = $(this);
            const elemData = elemJQ.data();
            const elemHeaderName = elemData["headerName"];
            const elemCurrentSearch = TableFilters.getExecutedStatus(elemHeaderName) || TableFilters.getPendingStatus(elemHeaderName) || "";
            var inputText = "";
            if (elemCurrentSearch.length) {
                inputText = elemCurrentSearch;
            }

            const impHtml =
                '<input type="text" class="column-search search-input" autofocus=true name="' +
                elemHeaderName +
                '" placeholder="Search" value="' +
                inputText +
                '" />';
            const btnHtml =
                '<button id="btnPowertipSearch" class="ui-button ui-widget ui-corner-all column-search powertip-search ui-button-icon-only"><span class="column-search"></span></button>';

            e.stopPropagation(); // Prevent column sorting on click
            $(this).data("powertip", impHtml + " " + btnHtml);
            $.powerTip.toggle(this);
            $("#powerTip input").select();
        });
    }

    static handleTableColumnSearch(table, input) {
        const inputVal = input.val();
        const inputName = input.attr("name");
        const span = $('button.column-search.powertip-toggle[name="' + inputName + '"] span');
        const titleDiv = $('div.column-title[name="' + inputName + '"]');
        const emptySearchBtnClass = "unsearched";
        const filledSearchBtnClass = "searched";
        $.powerTip.hide();
        TableFilters.setExecutedStatus(inputName, inputVal);

        // Get the column then execute search
        const col = table.table().column("" + inputName + ":name");
        TableFilters.searchTable(inputVal, col);

        if (inputVal && inputVal.length) {
            span.removeClass(emptySearchBtnClass).addClass(filledSearchBtnClass);
            titleDiv.removeClass(emptySearchBtnClass).addClass(filledSearchBtnClass);
        } else {
            span.removeClass(filledSearchBtnClass).addClass(emptySearchBtnClass);
            titleDiv.removeClass(filledSearchBtnClass).addClass(emptySearchBtnClass);
        }
    }

    static handleSearchTooltipExit(e) {
        if (e.type === "keyup" && (e.keyCode === 27 || e.key === "Escape")) {
            $.powerTip.hide();
        } else if (e.type === "click") {
            $.powerTip.hide();
        }
    }
    static getPendingStatus(name) {
        return TableFilters.pendingList[name];
    }
    static setPendingStatus(name, value) {
        TableFilters.pendingList[name] = value;
    }
    static getExecutedStatus(name) {
        return TableFilters.executedList[name];
    }
    static setExecutedStatus(name, value) {
        TableFilters.executedList[name] = value;
    }
}
module.exports = TableFilters;
