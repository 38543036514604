const ErrorMessage = require("./error-message.js");

const SAMPLE_FITS_FILES = [
    { file: "/static/data/fits/casa.fits.gz", options: { parentFile: "fits/casa.fits", scale: "log", colormap: "cool" } },
    { file: "/static/data/fits/coma.fits", options: { scale: "linear" } },
    { file: "/static/data/fits/m13.fits", options: { scale: "linear", colormap: "sls" } },
    { file: "/static/data/fits/3c273.fits.gz", options: { parentFile: "fits/3c273.fits", colormap: "viridis", scale: "log" } },
    {
        file: "/static/data/fits/candels_egs_60mas_215.218420_53.079091_300.0 pixx150.0 pix_astrocut.fits",
        options: { colormap: "sls", scale: "linear", parentFile: "foobar.fits" }
    }
    /*
    {
        file: "/static/data/fits/astrocut_215.21842_53.079091_300x150px.zip",
        options: {
            //parentFile: "candels_egs_60mas_215.218420_53.079091_300.0 pixx150.0 pix_astrocut.fits",
            colormap: "viridis",
            scale: "linear"
        }
    }
    */
    //{ file: "/static/data/fits/hlsp_candels_hst_acs_cos-tot-sect23_f606w_v1.0_exp.fits", options: {} },
    //{ file: "/static/data/fits/NGA_M101-nd-int.fits", options: {} }
];

class FitsImages {
    static init() {
        FitsImages.imageCount = 0;
        $("#images-row").empty();
        var index = 0;
        for (index = 0; index < SAMPLE_FITS_FILES.length; index++) {
            FitsImages.show(SAMPLE_FITS_FILES[index].file, SAMPLE_FITS_FILES[index].options);
        }
    }
    static show(file, opts) {
        const myopts = opts || {};
        JS9.imageOpts.wcsunits = "degrees";
        // make a unique id for the JS9 divs
        const id = "fits-images-" + FitsImages.imageCount++;
        // make up the html with the unique id. Enclosing div needed for formatting menu bars on top of images.
        const html = sprintf(
            "<div><div class='JS9Menubar' id='%sMenubar' data-width='300px'></div><div class='JS9' id='%s' data-width='300px' data-height='250px'></div></div>",
            id,
            id
        );
        $(html).appendTo($("#images-row"));
        // create the new JS9 display, with associated plugins
        JS9.AddDivs(id);
        //TODO: investigate should we use JS9.Preload instead?
        //JS9.Preload(file, opts, { display: id });
        JS9.Load(file, myopts, { display: id });
    }
    static getImage(ra, dec, hSize, vSize) {
        const api = "//mastdev.stsci.edu/zcut/api/v0.1/astrocut?ra=189.23219&dec=62.23858&x=50&y=50&unit=px";
        $.ajax({
            url: api,
            method: "get",
            success: function() {
                //extract FITS from ZIP file
            },
            error: function(data, textStatus, errorThrown) {
                console.error(textStatus + ": " + errorThrown);
                ErrorMessage.setMessage(textStatus + ": " + errorThrown);
            }
        }).done(function(jsonString) {});
    }
}
module.exports = FitsImages;
